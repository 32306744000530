.footerContent {
  background-color: #003466;
  z-index: 1049;
  position: absolute;
}
.footer-content {
  max-width: 430px;
  min-width: 300px;
  z-index: 1049;
}
