.backImageContent {
     height: 650px; 
}
.NotFound{
background: url("video/80911.jpg");
background-position: bottom;
background-size: cover;
height: 650px;
order: -1;
z-index: -10;
}
.notFoundAlign{
     padding-top: 15%;
     padding-left:10%;
     max-width: 800px;
     min-width: 280px;
}
@media only screen and (min-width: 2300px) and (max-width: 5000px) {
     .notFoundAlign{
          padding-top: 10%;
          max-width: 1500px;
          min-width: 480px;
     }
     .headAlign{
          font-size: 60px;
     }
     .contentAlign{
          font-size: 30px;
          line-height: 40px;
          
     }
}
@media only screen and (min-width: 1800px) and (max-width: 2300px) {
     .notFoundAlign{
          padding-top: 10%;
          max-width: 1200px;
          min-width: 480px;
     }
     .headAlign{
          font-size: 50px;
     }
     .contentAlign{
          font-size: 25px;
          line-height: 35px;
          
     }
}
@media only screen and (min-width: 1000px) and (max-width: 1800px) {
     .headAlign{
          font-size: 40px;
     }
     .contentAlign{
          font-size: 20px;
          line-height: 30px;
     }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
     .headAlign{
          font-size: 34px;
     }
     .contentAlign{
          font-size:17px;
          line-height: 30px;
     }
     .backImageContent{
          height: 500px;
     }
}
@media only screen and (min-width: 500px) and (max-width: 800px) {
     .headAlign{
          font-size: 30px;
     }
     .contentAlign{
          font-size:15px;
          line-height: 25px;
     }
     .backImageContent{
          height: 450px;
     }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
     .headAlign{
          font-size: 20px;
     }
     .contentAlign{
          font-size:10px;
          line-height: 20px;
     }
     .backImageContent{
          height: 400px;
     }
     .notFoundAlign{
          padding-top: 20%;
          padding-left:10%;
          max-width: 800px;
          min-width: 200px;
     }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
     .headAlign{
          font-size: 20px;
     }
     .contentAlign{
          font-size:10px;
          line-height: 20px;
     }
     .backImageContent{
          height: 400px;
     }
     .notFoundAlign{
          padding-top: 20%;
          padding-left:10%;
          max-width: 800px;
          min-width: 200px;
     }
}