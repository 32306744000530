@import url("https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap");

#noticeBoard {
  padding: 0;
  margin: 0;
  margin-right: 1%;
}

#noticeBoard {
  margin-top: 2px;
  margin-right: 20px;
  height: 490px;
  border-radius: 10px;
  position: relative;
  background-color: #273e57;
  /* max-width: 1900px; */
  font-family: "Architects Daughter", cursive !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.noticeOpen1 {
  animation: noticeOpen 2s;
  height: 490px;
  /* opacity: 1; */
}
@keyframes noticeOpen {
  from {
    height: 70px;
  }

  to {
    height: 490px;
  }
}
.noticeClose {
  animation: noticeClose 2s;
  height: 70px;
  /* opacity: 1; */
}
@keyframes noticeClose {
  from {
    height: 490px;
  }

  to {
    height: 70px;
  }
}
#add_note {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: #4e80b6;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  font-family: "Montserrat", sans-serif;
  margin-top: 430px;
  position: absolute;
  outline: none;
  cursor: pointer;
  right: 10px;
}

#add_note:hover {
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6;
}

#modal {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 490px;
  background-color: rgba(72, 124, 140, 0.507);
  border-radius: 10px;
}

#inner_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 490px;
}

#inner_modal textarea {
  width: 276px;
  height: 276px;
  padding: 25px;
  outline: none;
  resize: none;
  font-size: 1.5rem;
  font-family: "Architects Daughter", cursive !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.9);
}

#inner_modal i {
  font-size: 1.4rem;
  color: rgb(19, 54, 87) !important;
  cursor: pointer;
  margin-top: -245px;
  margin-left: -55px;
  padding-right: 65px;
  transition: 1s ease-in-out;
  z-index: 10;
}
.noteClose {
  position: absolute;
  right: 0;
  color: rgba(44, 48, 56, 0.59);
}
#inner_modal i:hover {
  color: lightgray;
}

.containerNotice {
  /* width: 1280px; */
  /* margin: auto; */
  padding: 0;
  margin: 0;
}

#header {
  color: white;
  padding: 0 20px;
  min-height: 70px;
  text-shadow: 1px 1px black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  font-family: "Architects Daughter", cursive !important;
}
#headerNotice {
  color: white !important;
  padding: 0 20px !important;
  min-height: 70px !important;
  text-shadow: 1px 1px black !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: "Architects Daughter", cursive !important;
}
.fas {
  color: #c2ff3d;
}

#all_notes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 15px;
  position: absolute;
  /* z-index: 9; */
  height: 420px;
  overflow-y: scroll;
  width: 100%;
}
#all_notes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
#all_notes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
#all_notes::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
#all_notes::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}
.note {
  width: 160px;
  height: 160px;
  transition: 2s;
  cursor: pointer;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  word-wrap: break-word;
}
.note::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.note::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0);
  border-radius: 0px;
}
.note::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 0px;
}
.note::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.note h1 {
  font-size: 1.5rem;
  word-wrap: break-word;
}

.details {
  margin-top: 25px;
  padding: 0 15px;
  font-size: 1.5rem;
}

.details i {
  color: whitesmoke;
  cursor: pointer;
  text-shadow: 1px 1px #bbb;
}

@media (max-width: 1280px) {
  .containerNotice {
    width: 100%;
  }
}

@media (max-width: 200px) {
  #header {
    padding: 20px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 470px) {
  .headerNotice {
    display: none;
  }
  .bodyContain {
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin: 0px !important;
  }

  .note {
    width: 130px;
    height: 130px;
  }
}

.outer {
  position: relative;
  margin: auto;
  width: 35px;
  margin-top: 7px;
  margin-right: 0px;
  cursor: pointer;
}

.inner {
  width: inherit;
  text-align: center;
}

.noticeOpen {
  font-size: 0.8em;
  line-height: 1.4em;
  margin-top: 7px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}

.inner:before,
.inner:after {
  position: absolute;
  content: "";
  height: 0.5px;
  width: inherit;
  background: #42b5d8;
  left: 0;
  transition: all 0.3s ease-in;
}

.inner:before {
  top: 50%;
  transform: rotate(45deg);
}

.inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

.outer .cl label {
  opacity: 1;
}

.outer.cl .inner:before,
.outer.cl .inner:after {
  margin-top: 5px;
  transform: rotate(0);
}

.outer.cl .inner:before {
  top: 0;
}

.outer.cl .inner:after {
  bottom: 0;
}
