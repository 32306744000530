/* Service */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap");
.serviceBack {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.549),
      rgba(255, 255, 255, 0.85)
    ),
    url("../../../images/imageBack2.jpg");
  background-position: center;
  padding-bottom: 15%;
  padding-top: 5%;
}
.containerService {
  /* position: absolute; */
  margin-top: 5%;
  /* margin-bottom: 10%; */

  height: 300px;
  /* width: 600px; */

  justify-content: center;
  left: calc(50% - 300px);
  display: flex;
}
.containerService1 {
  display: none;
  justify-content: center;
}

.cardService {
  display: flex;
  height: 280px;
  width: 200px;
  /* min-width: 200px; */
  background-color: #4e80b6;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #000;
  /* margin-left: -50px; */
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
}
.cardService1 {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 280px;
  /* width: 200px; */
  max-width: 400px;
  min-width: 200px;
  background-color: #2b315a;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #000;
  /* margin-left: -50px; */
  transition: 0.4s ease-out;

  left: 0px;
}
.cardService:not(:first-child) {
  margin-left: -30px;
}

.cardService:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.cardService:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.titleService {
  color: white;
  font-weight: 500;
  position: absolute;
  left: 20px;
  top: 15px;
}

.barService {
  position: absolute;
  top: 100px;
  left: 20px;
  height: 5px;
  width: 150px;
}

.emptybarService {
  background-color: #1f2021;
  width: 100%;
  height: 100%;
}

.filledbarService {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 0px;
  height: 100%;
  background: rgb(0, 154, 217);
  background: linear-gradient(
    90deg,
    rgba(0, 154, 217, 1) 0%,
    rgba(217, 147, 0, 1) 65%,
    rgba(255, 186, 0, 1) 100%
  );
  transition: 0.6s ease-out;
}

.cardService:hover .filledbarService {
  width: 120px;
  transition: 0.4s ease-out;
}

.circleService {
  position: absolute;
  top: 150px;
  left: calc(50% - 60px);
}

.strokeService {
  stroke: white;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

svg {
  fill: #17141d;
  stroke-width: 2px;
}

.cardService:hover .stroke {
  stroke-dashoffset: 100;
  transition: 0.6s ease-out;
}

.contentStyle {
}
.content1 {
  /* background-color: #fff;
  z-index: 0; */
  margin-left: 20%;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}
:root {
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: #4e80b6;
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

.boxCard p {
  color: var(--grayishBlue);
}

.boxCard {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 30px;
  margin: 20px;
  width: 250px;
}
.boxCard:hover {
  cursor: pointer;
  box-shadow: rgba(174, 174, 179, 0.19) 0px 10px 20px,
    rgba(179, 182, 184, 0.23) 0px 6px 6px;
}
.cardImage {
  float: right;
}
@media (max-width: 350px) and (min-width: 0px) {
  .boxCard {
    height: auto;
    width: auto !important;
  }
  .row1-container {
    padding: 5%;
    padding-top: 14%;
  }
  .content1 {
    margin-left: 0%;
  }
}
@media (max-width: 350px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: auto !important;
  }
  .row1-container {
    padding: 5%;
    padding-top: 14%;
  }
}
@media (max-width: 450px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: 70%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: 60%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 950px) and (min-width: 600px) {
  .boxCard {
    text-align: center;
    height: auto;
    width: 50%;
  }
  .content1 {
    margin-left: 0%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cyan {
  border-top: 3px solid var(--cyan);
}
.red {
  border-top: 3px solid var(--red);
}
.blue {
  border-top: 3px solid var(--blue);
}
.orange {
  border-top: 3px solid var(--orange);
}

.cardSquareHead1 {
  color: var(--varyDarkBlue);
  font-weight: var(--weight3);
}
.cardSquareHead1Dark {
  color: #d5d5da;
  font-weight: var(--weight3);
}
.row2-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 950px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .boxCard-down {
    position: relative;
    top: 150px;
  }
  .boxCard {
    width: 25%;
    /* width:320px; */
  }
}

.attribution {
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

.content0 {
  background-image: url("../../../images/template01.png");
  background-position: center;
  background-size: cover;
  z-index: 1;
  height: 595px;
}
.content0Dark {
  background-image: url("../../../images/template01.png");
  background-position: center;
  background-size: cover;
  color: #fff;
  z-index: 1;
  height: 595px;
}
.alignContent0 {
  width: 50%;
}
.content0Align {
  padding-top: 15%;
  max-width: 800px;
  min-width: 480px;
}
.card1s-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* background-color: #fff; */
  flex-wrap: wrap;
}
.content1Align {
  /* background-color: #fff; */
}
.card1 {
  margin: 30px auto;
  width: 300px;
  height: 400px;
  border-radius: 40px;
  box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
    -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
  cursor: pointer;
  background-color: #fff;
  transition: 0.4s;
}

.card1 .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card1 .card_image img {
  width: inherit;
  height: inherit;
  height: 300px;
  border-radius: 40px;
  /* object-fit: cover; */
}

.card1 .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  margin-top: -120px;
  height: 40px;
}

.card1:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(107, 106, 106, 0.25),
    -5px -5px 30px 15px rgba(124, 123, 123, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}
.contentAbout {
  margin-top: 2%;
  margin-bottom: 50px;
  /* margin-left:5%;
     margin-right:5%; */
  /* width: 90%; */
}
.contentAboutProfile {
  background-image: url("../../../images/about-me.jpg");

  background-size: cover;
  border: 10px solid #0b1c30;
  height: 355px;
  margin-top: 100px;
  z-index: 100;
}
.BFont {
  color: #9dc1e4ec;
  font-size: 70px;
}
.contentAbout2 {
  background: linear-gradient(to bottom, #0b1c30, #1e39533f);
  /* padding-bottom: 30px; */
  /* width:100%; */
  z-index: 100;
  white-space: nowrap;
}
.contentAbout0 {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.981),
      rgba(255, 255, 255, 0.733),
      rgba(255, 255, 255, 0.981)
    ),
    url("../../../images/imageBack2.jpg");

  /* background-size: cover; */
  padding-bottom: 20%;
}
.contentAbout0Dark {
  padding-bottom: 20%;
  background-image: linear-gradient(
      to bottom,
      rgba(33, 37, 52, 0.946),
      rgba(33, 37, 52, 0.966),
      rgba(33, 37, 52, 0.953)
    ),
    url("../../../images/imageBack2.jpg");
}
.contentAbout1 {
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.938), rgba(18, 53, 102, 0.493)),url("../../../images/back3.jpg"); */
  /* position: absolute; */
  background-size: cover;

  height: 730px;

  z-index: 10;
}
.contentAbout3 {
  padding-left: 5%;
  padding-right: 5%;
}
.aboutTitle {
  color: #4e80b6;
}
.aboutContentContainer {
  background-image: linear-gradient(
    to bottom,
    rgba(155, 174, 214, 0.301),
    rgba(99, 150, 192, 0.404)
  );
  border-radius: 5px;
  padding: 5%;
  padding-top: 2%;
}
.aboutContent {
  font-size: 21px;
  line-height: 30px;
  color: #263d6b;
  letter-spacing: 0.7px;
  text-align: justify;
  text-shadow: 1px 1px #d5d5da;
  text-justify: inter-word;
}
@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 17px;
    line-height: 21px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url("../../../images/back3.jpg");
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url("../../../images/imageBack2.jpg");
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0 {
    height: 300px;
  }
  .content0Dark {
    height: 300px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0 {
    height: 240px;
  }
  .content0Dark {
    height: 240px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0Align {
    padding-top: 7%;
    padding-left: 0%;
    max-width: 200px;
    min-width: 200px;
  }
  .card1 {
    height: 370px;
    width: 200px;
  }
  .card1 .card_title {
    margin-top: -70px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 410px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 17px;
    line-height: 21px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url("../../../images/back3.jpg");
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url("../../../images/imageBack2.jpg");
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0 {
    height: 300px;
  }
  .content0Dark {
    height: 300px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0Align {
    padding-top: 8%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .card1 {
    width: 250px;
    height: 350px;
  }
  .card1 .card_title {
    margin-top: -60px;
  }
}
@media only screen and (min-device-width: 410px) and (max-device-width: 500px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 8%;
    padding-right: 8%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 19px;
    line-height: 24px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url("../../../images/back3.jpg");
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url("../../../images/imageBack2.jpg");
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content0 {
    height: 450px;
    background-position: -70px;
  }
  .content0Dark {
    height: 450px;
    background-position: -70px;
  }
  .alignContent0 {
  }
  .content0Align {
    padding-top: 13%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
  .card1 {
    height: 350px;
  }
  .card1 .card_title {
    margin-top: -60px;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 550px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 18%;
    padding-right: 18%;
    /* width: 90%; */
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url("../../../images/back3.jpg");
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url("../../../images/imageBack2.jpg");
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content0 {
    height: 450px;
    background-position: -70px;
  }
  .content0Dark {
    height: 450px;
    background-position: -70px;
  }
  .alignContent0 {
  }
  .content0Align {
    padding-top: 13%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
  .card1 {
    height: 380px;
  }
  .card1 .card_title {
    margin-top: -90px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 991px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }

  .contentAbout {
    padding-left: 18%;
    padding-right: 18%;
    /* width: 90%; */
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url("../../../images/back3.jpg");
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1270px) {
  .contentAbout {
    padding-left: 4%;
    padding-right: 4%;
    /* width: 90%; */
  }
  .cardService {
    width: 180px;
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1400px) {
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 5%;
  }
  .content0 {
    height: 855px;
  }
  .content0Dark {
    height: 855px;
  }
  .content0Align {
    padding-top: 15%;
    max-width: 600px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -170px;
  }
  .content1Align {
    width: 55%;
  }
}
@media only screen and (min-device-width: 1400px) and (max-device-width: 1800px) {
  .contentAbout {
    padding-left: 7%;
    padding-right: 7%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 7%;
  }
  .content0 {
    height: 915px;
  }
  .content0Dark {
    height: 915px;
  }
  .content0Align {
    padding-top: 15%;
    max-width: 800px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -120px;
  }
  .content1Align {
    width: 60%;
  }
}
@media only screen and (min-device-width: 1800px) and (max-device-width: 2100px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 955px;
  }
  .content0Dark {
    height: 955px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1200px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -120px;
  }

  .content1Align {
    width: 60%;
  }

  .card1 {
    margin: 30px auto;
    width: 400px;
    height: 450px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -160px;
    height: 40px;
  }
}
@media only screen and (min-device-width: 2100px) and (max-device-width: 2300px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 1105px;
  }
  .content0Dark {
    height: 1105px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1200px;
    min-width: 480px;
  }

  .content1Align {
    width: 60%;
  }
  .card1 {
    margin: 30px auto;
    width: 400px;
    height: 450px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -160px;
    height: 40px;
  }
  .content1 {
    margin-top: -100px;
  }
  .content1Align {
    width: 55%;
  }
}
@media only screen and (min-device-width: 2300px) and (max-device-width: 3000px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 1105px;
  }
  .content0Dark {
    height: 1105px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1500px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -100px;
  }
  .content1Align {
    width: 60%;
  }
  .card1 {
    margin: 30px auto;
    width: 500px;
    height: 530px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -200px;
    height: 40px;
  }

  .content1Align {
    width: 55%;
  }
}
