.bodyContent {
  /* height: 65rem; */
  /* pointer-events:none; */
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.93),
      rgba(255, 255, 255, 0.93)
    ),
    url("../../images/imageBack2.jpg");
  color: black;

}
.layerBody{
  /* pointer-events:none;  */
  display:none;
  background-color: #08111f9c;
  height:10000px;
  z-index: 10000;
  width:100%;
  position: fixed;
}
.createContentStyle {
  padding: 4%;
  margin: 0%;
  padding-top: 3%;
}

.bodyContentDark {
  /* height: 155rem; */
  background-color: #212534;
  background-image: linear-gradient(
      to bottom,
      rgba(33, 37, 52, 0.946),
      rgba(33, 37, 52, 0.953)
    ),
    url("../../images/imageBack2.jpg");
  /* color: white; */
}
.leftHead {
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #003466;
  padding: 10px;
  padding-left: 25px;
}
.leftHead:hover {
  background-color: #cdd3da;
}
.leftHeadDark {
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #87c0f5;
  padding: 10px;
  padding-left: 25px;
}
.leftHeadDark:hover {
  color: #003466;
  background-color: #cdd3da;
}
.leftHeadTop {
  font-size: 18px;
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #003466;
}
.leftHeadTopDark {
  font-size: 18px;
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #87c0f5;
}
.leftHeadIcon {
}

.RentalUnitsMapOn:hover .menuIndex {
  background-color: #4e80b67a;
}
.RentalUnitsMapOff .menuIndex {
  background-color: #4e80b67a !important;
}

.containerMenu {
  display: inline-block;
  cursor: pointer;
  padding-right: 21px;
  padding-left: 12px;
  padding-top: 7px;
}
.menuIndex {
  position: relative;
  text-align: right;
  float: right;
  margin-right: -52px;
  margin-top: -8px;
  z-index: 999;
  background-color: #4e80b63b;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 3.3px;
  background-color: #fff;
  margin: 4.5px 0;
  transition: 0.4s;
}

.change .bar1 {
  -webkit-transform: rotate(-45deg) translate(-4px, 7px);
  transform: rotate(-45deg) translate(-4px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  -webkit-transform: rotate(45deg) translate(-4px, -6px);
  transform: rotate(45deg) translate(-4px, -7px);
}

.RentalUnitsMapOff {
  animation: slideRight 1s linear 1;
  width: 280px;

  transform: translateX(-280px);
  z-index: 999;
  height: 100%;

  position: fixed;
}
.top130 {
  top: 48px;
}
.RentalUnitsMapOn {
  animation: slideRight1 1s linear 1;
  width: 280px;
  z-index: 999;
  height: 100%;
  position: fixed;
}

@keyframes slideRight {
  0% {
    transform: translateX(0px);
  }
  55% {
  }
  100% {
    transform: translateX(-280px);
  }
}
@keyframes slideRight1 {
  0% {
    transform: translateX(-280px);
  }
  99% {
  }
  100% {
    transform: translateX(0px);
  }
}

@media only screen and (min-width: 220px) and (max-width: 800px) {
  .RentalUnitsMapOff {
    animation: slideRight 1s linear 1;
    width: 250px;
    transform: translateX(-250px);
    position: absolute;
    z-index: 999;
  }
  .RentalUnitsMapOn {
    transform: translateX(0px);
    animation: slideRight1 1s linear 1;
    width: 250px;
    position: absolute;
  }
  @keyframes slideRight {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-250px);
    }
  }
  @keyframes slideRight1 {
    0% {
      transform: translateX(-250px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}
