@import url("https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap");

.contentStyle {
  margin: 0%;
  padding-top: 1%;
  width: 100%;
}
.contentStyleDark {
  margin: 0%;
  padding-top: 1%;
  color: #fff;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.buttonNext {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: none;
  background-color: #4e80b67e ;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonNext:hover:before {
  content: "Next ";
}

.buttonPrevious {
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  border: none;
  background-color: #4e80b67e ;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonPrevious:hover:after {
  content: " Previous";
}

/* content styles */
.cursive {
  font-family: "Mr Dafoe";
  padding-top: 0;
  margin-top: -20px;
  font-size: 24px;
  color: white;
  text-shadow: 0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1;
  transform: rotate(-1deg);
}
.textShadow1 {
  text-shadow: 2px 2px 3px rgb(89, 225, 26);
}
.textShadow2 {
  text-shadow: 2px 2px 3px rgb(40, 140, 247);
}
.textShadow3 {
  text-shadow: 2px 2px 3px rgb(214, 19, 19);
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
  .userContentHead {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody h1 span {
    font-size: 1.7rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody p span {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 450px) {
  .userContentHead {
    font-size: 2rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody p span {
    font-size: 1.7rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody h1 span {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody {
    padding: 20px;
  }
}
