.show {
  display: block;
}
.hide {
  display: none;
}
.modalStyle {
  border-radius: 25px;
}
.modalContent {
  font-size: 19px;
  font-weight: 100;
  margin-top: -40px;
  margin-bottom: -20px;
}

.alertContain{
  position: fixed;
  right:15px;
  z-index: 1000000;
}


.NotificationWindow {
  z-index: 1000000;
  padding: 15px;
  min-width: 300px;
  max-width: 600px;
 
  border-radius: 10px;
  animation: AlertRight 1s linear ;
  opacity: 1;
}
.topMargin10{
  top: 10px !important;
}
.topMargin40{
  top: 100px !important;
}
.successAlert{
  background-color: rgba(77, 209, 108, 0.795) ;
  box-shadow: 0 4px 13px -8px rgb(55, 194, 55);
  color:rgb(13, 32, 13) ;
}
.successAlert .fa{
    color:rgb(23, 48, 23) ;
}
.successAlert .fa:hover{
  color:rgba(95, 158, 32, 0.637) ;
}
.successAlert .bar{
  background: rgb(17, 83, 17);
}
.warningAlert{
  background-color: rgba(252, 255, 85, 0.842);
  box-shadow: 0 4px 13px -8px rgba(252, 255, 85, 0.842);
  color:rgb(111, 112, 12) ;
}
.warningAlert .fa{
    color:rgba(109, 110, 27, 0.884);
}
.warningAlert .fa:hover{
  color:rgba(181, 184, 38, 0.842);
}
.warningAlert .bar{
  background: rgb(195, 207, 19);
}

.infoAlert{
  background-color: rgba(52, 133, 153, 0.842);
  box-shadow: 0 4px 13px -8px rgba(90, 202, 230, 0.842);
  color:rgba(255, 255, 255, 0.842);
}
.infoAlert .fa{
    color:rgba(255, 255, 255, 0.842);
}
.infoAlert .fa:hover{
  color:rgba(46, 95, 107, 0.842);
}
.infoAlert .bar{
  background: rgba(46, 95, 107, 0.842);
}

.dangerAlert{
  background-color: rgba(245, 76, 84, 0.822);
  box-shadow: 0 4px 13px -8px rgba(240, 71, 29, 0.842);
  color:rgba(255, 255, 255, 0.842);
}
.dangerAlert .fa{
    color:rgba(255, 255, 255, 0.842);
}
.dangerAlert .fa:hover{
  color:rgba(235, 127, 100, 0.842);
}
.dangerAlert .bar{
  background: rgba(243, 138, 112, 0.842);
}

.lightAlert{
  background-color: rgba(224, 220, 220, 0.885);
  box-shadow: 0 4px 13px -8px rgb(99, 98, 98);
}
@keyframes AlertRight {
  0% {
    opacity: 0.2;
   
    /* display:block; */
  }

  50% { 
    opacity: 1;
    display: block;
     transform: translate(1px, 1px) rotate(0deg); }
  55% { transform: translate(-1px, -2px) rotate(-1deg); }
  60% { transform: translate(-3px, 0px) rotate(1deg); }
  65% { transform: translate(3px, 2px) rotate(0deg); }
  70% { transform: translate(1px, -1px) rotate(1deg); }
  75% { transform: translate(-1px, 2px) rotate(-1deg); }
  80% { transform: translate(-3px, 1px) rotate(0deg); }
  85% { transform: translate(3px, 1px) rotate(-1deg); }
  90% { transform: translate(-1px, -1px) rotate(1deg); }
  95% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); 
   }

}


.notificationText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}
.notificationClose {
  margin-top: 10px;
  margin-right: 100px;
  z-index: 501;
  position: absolute;
}


.notificationText {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
  }

@media only screen and (min-width: 425px) and (max-width: 600px) {
    .notificationText {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2px;
    }
}

@media only screen and (min-width: 200px) and (max-width: 425px) {
    .notificationText {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
    }
}