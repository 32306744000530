.bg-morning{
  background-color: #7fb1e6c4;
  height:100%;
}
.bg-evening{
  background-color: #e7bd3494;
  height:100%;
}
.bg-night{
  background-color: #0c376894;
  height:100%;
}
.text-success1{
  color: #65fc51;
}
.bg-success1{
  color: #313131;
  background-color: #65fc51;
}
.learnerDashStyle {
  /* padding-left: 4%; */
  margin: 0%;
  padding-top: 1%;
  width: 100%;
  /* background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.93),
    rgba(255, 255, 255, 0.93)
  ); */
  margin-bottom: 50%;
  /* url("../../images/imageBack2.jpg"); */
}

.learnerDashStyleDark {
  width: 100%;

  margin: 0%;
  padding-top: 1%;
  color: #fff;
  margin-bottom: 50%;
  /* background-image: linear-gradient(
    to bottom,
    rgba(33, 37, 52, 0.946),
    rgba(33, 37, 52, 0.953)
  ); */
  /* url("../../images/imageBack2.jpg"); */
}

.calOpen {
  animation: calOpen 2s;
  height: 420px;
  /* opacity: 1; */
}
@keyframes calOpen {
  from {
    height: 70px;
  }

  to {
    height: 420px;
  }
}
.calClose {
  animation: calClose 2s;
  height: 70px;
  /* opacity: 1; */
}
@keyframes calClose {
  from {
    height: 420px;
  }

  to {
    height: 70px;
  }
}
.progress-bar {
  background-color: #2d5d91;
}
.classSquareHead1 {
  color: #2d5d91;
  padding: 0px;
  margin: 0px;
  padding-top: 9px;
  font-size: 18px;
}
.classSquareHead1Dark {
  color: #d5d5da;
  padding: 0px;
  margin: 0px;
  padding-top: 9px;
  font-size: 18px;
}
.InstructorName {
  background-color: #4e80b6;
  color: #fff;
  cursor: pointer;
}

.InstructorName1:hover .InstructorName {
  background-color: #fff;
  color: #4e80b6;
}
.InstructorName1:hover {
  background: #4e80b67a;
  color: #fff;
}
.InstructorName1:active {
  background: #034b8fa9;
}

.InstructorName1:focus {
  background: #003466;
}
.classBox {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #6b9ed4;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 520px;
}
.classBoxDark {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #6b9ed4;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 520px;
  background: #3e62798a;
}
.classModal {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background: rgba(255, 255, 255, 0.938);
}
.classModalDark {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;

  background: #3e6279e1;
}
.classBoxImage {
  border-radius: 35px;

  margin-top: 9px;
  margin-left: 9px;
  margin-bottom: 9px;
  padding-top: 0px;
  border: 1px solid #7e9cbb;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 45px;
}
.pointImage {
  margin-top: 9px;
  margin-bottom: 9px;
  padding-top: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 55px;
}
.angleImage {
  margin-top: 15px;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 45px;
  border-radius: 15px;
}
.folderImage {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 65px;
}
@keyframes heart1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.justImage {
  animation: heart1 2.5s infinite alternate;
  position: absolute;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 50px;
}
.leftDashContainer {
  width: 25%;
}
.rightDashContainer {
  width: 75%;
}
.bg-throout {
  background-color: #4e80b6;
  color: #fff;
}
.mobile-bottom-nav {
  position: fixed;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 3000;

  will-change: transform;
  transform: translateZ(0);

  display: flex;

  height: 60px;
  /* width: 100%; */
  /* box-shadow: 0 -2px 5px -2px #333; */
  /* background-color: #fff; */
}
.navBottom {
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.navBottom1 {
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 0px;
  margin-top: -50px;
  height: 50px;
  width: 360px;
  /* border-radius: 50px; */
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: #4e80b6b8;
  z-index: 1052 !important;
}
#navBottom2 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-right: 10px;
  position: absolute;
  /* margin:10px; */
  height: 500px;
  width: 340px;
  border-radius: 10px;
  background-color: #4e80b6;
  z-index: 1051;
}
.navBottom2Up {
  animation: navmove 2s;
  margin-top: -620px;
  opacity: 1;
}
@keyframes navmove {
  from {
    opacity: 1;
    margin-top: 0px;
  }

  to {
    opacity: 1;
    margin-top: -620px;
  }
}

.navBottom2Down {
  animation: navmove1 2s;
  margin-top: 0px;
  opacity: 0;
}
@keyframes navmove1 {
  from {
    margin-top: -620px;
    opacity: 1;
  }
  99% {
    opacity: 1;
    margin-top: 1px;
  }
  to {
    opacity: 0;
    margin-top: 0px;
  }
}
.navBottom3 {
  overflow-y: scroll;
  height: 570px;
  background-color: #f7f7f7;
}
.navBottom3Dark {
  overflow-y: scroll;
  height: 570px;
  background-color: rgba(33, 37, 52, 0.946);
}

.navBottom3::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.navBottom3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}

.navBottom3Dark::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.navBottom3Dark::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
.navBottom3Dark::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}
.navBottomText {
  color: #fff;
  font-size: 19px;
  margin-top: 16px;
  padding-left: 18px !important;
  font-weight: 600 !important;
  float: left;
}
.navBottomIcon {
  float: right;
  color: #fff;
  font-size: 19px;
  margin-top: 14px;
  cursor: pointer;
  padding-right: 18px !important;
  font-weight: 600 !important;
}
.navBottom1 img {
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
  float: left;
}
.navCalender {
  display: none;
}
.rotate {
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
  transform-origin: 20% 60%;
}

.icon-rotate {
  -moz-transition-duration: 0.7s;
  -o-transition-duration: 0.7s;
  -webkit-transition-duration: 0.7s;
  transition-duration: 0.7s;
  /* display: inline-block; */
}
@media only screen and (min-width: 1930px) and (max-width: 3050px) {
  .leftDashContainer {
    width: 20%;
  }
  .rightDashContainer {
    width: 80%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1290px) {
  .leftDashContainer {
    width: 32%;
  }
  .rightDashContainer {
    width: 67%;
  }
  .container-calendar {
    min-width: 200px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 1000px) {
  .leftDashContainer {
    width: 0%;
    display: none;
  }
  .rightDashContainer {
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  #noticeBoard {
    margin-right: 0 !important;
  }
  .navCalender {
    display: block;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .navBottom {
    justify-content: center;
  }
}

@media only screen and (min-height: 200px) and (max-height: 300px) {
  #navBottom2 {
    height: 250px !important;
  }
}
@media only screen and (min-height: 300px) and (max-height: 400px) {
  #chatview {
    height: 223px !important;
  }
  #navBottom2 {
    height: 272px !important;
  }

  .navBottom3 {
    height: 272px !important;
  }
  .navBottom3Dark {
    height: 272px !important;
  }
  .p1 #profile {
    height: 60px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 223px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 210px !important;
  }
  #friends {
    height: 160px !important;
  }
  .navBottom2Up {
    animation: navmove 2s;
    margin-top: -320px;
    opacity: 1;
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -320px;
    }
  }

  .navBottom2Down {
    animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @keyframes navmove1 {
    from {
      margin-top: -320px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 105px !important;
  }
}
@media only screen and (min-height: 400px) and (max-height: 500px) {
  #chatview {
    height: 323px !important;
  }
  #navBottom2 {
    height: 372px !important;
  }
  .navBottom3 {
    height: 372px !important;
  }
  .navBottom3Dark {
    height: 372px !important;
  }
  .p1 #profile {
    height: 65px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 320px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 310px !important;
  }
  #friends {
    height: 260px !important;
  }
  .navBottom2Up {
    animation: navmove 2s;
    margin-top: -420px;
    opacity: 1;
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -420px;
    }
  }

  .navBottom2Down {
    animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @keyframes navmove1 {
    from {
      margin-top: -420px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 200px !important;
  }
}
@media only screen and (min-height: 500px) and (max-height: 600px) {
  #chatview {
    height: 423px !important;
  }
  #navBottom2 {
    height: 472px !important;
  }
  .navBottom3 {
    height: 472px !important;
  }
  .navBottom3Dark {
    height: 472px !important;
  }
  .p1 #profile {
    height: 65px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 420px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 310px !important;
  }
  #friends {
    height: 360px !important;
  }
  .navBottom2Up {
    animation: navmove 2s;
    margin-top: -520px;
    opacity: 1;
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -520px;
    }
  }

  .navBottom2Down {
    animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @keyframes navmove1 {
    from {
      margin-top: -520px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 300px !important;
  }
}

.dayCell {
  height: auto !important;
  padding-top: 0px !important;
}
#dayHeader {
  padding-top: 0px !important;
}
