.sun-editor-editable table {
  /* display: table;
     table-layout: auto !important;
     border: 1px solid #ccc;
     width: 100%;
     max-width: 100%;
     margin: 0 0 10px;
     background-color: transparent;
     border-spacing: 0;
     border-collapse: collapse; */
}
.learnerDashStyle .sun-editor {
  margin-top: 60px;
  margin-bottom: 20px;
  border: none;
}
.learnerDashStyleDark .sun-editor {
  margin-top: 60px;
  margin-bottom: 20px;
  border: none;
}
.contentStyle .sun-editor {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent !important;
}
.contentStyleDark .sun-editor {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent !important;
  color: #fff !important;
}
.contentStyleDark .sun-editor-editable {
  background-color: transparent !important;
  color: #fff !important;
}
.contentStyle .sun-editor-editable {
  background-color: transparent !important;
}
.sun-editor-editable hr {
  border-top: 1px solid rgb(255, 255, 255) !important;
}
.contentStyleDark .sun-editor-editable[contenteditable="true"] .se-component {
  outline: none;
}
.previewQuestion .sun-editor {
  margin-top: 20px;
  margin-bottom: -20px;
  border: none;
  font-size: 26px !important;
  background-color: transparent !important;
}
.previewQuestion .sun-editor-editable {
  background-color: transparent !important;
  font-size: 18px !important;
}

.previewQuestionDark .sun-editor {
  margin-top: 20px;
  margin-bottom: -20px;
  border: none;
  font-size: 26px !important;
 
  background-color: transparent !important;
}
.previewQuestionDark .sun-editor-editable {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #fff !important;
}
@media only screen and (min-width: 00px) and (max-width: 500px) {
  .contentStyle .sun-editor {
    font-size: 16px !important;
    line-height: 35px !important;
  }
  .contentStyleDark .sun-editor {
    font-size: 16px !important;
  }
  .sun-editor span {
    font-size: 17px !important;
  }
  .sun-editor h1 {
    font-size: 19px !important;
  }
  .buttonNext {
    border: none !important;
    background-color: #4e80b67e !important;
    padding: 4px 10px !important;
  }
  .buttonNext:hover:before {
    content: "" !important;
  }

  .buttonPrevious {
    border: none !important;
    background-color: #4e80b67e !important;
    padding: 4px 10px !important;
  }

  .buttonPrevious:hover:after {
    content: "" !important;
  }
  .sun-editor-editable {
    padding: 7px !important;
    margin-block-start: 0.27em !important;
    margin-block-end: 0.27em !important;
  }
  .sun-editor-editable h1 {
    padding: 7px !important;
    margin-block-start: 0.27em !important;
    margin-block-end: 0.27em !important;
  }
}



