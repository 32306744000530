@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.avatar {
  height: 35px;
  width: 35px;
  border-radius: 15px;
  margin-left: 0px;
  border: 1px solid #fff;
}

.navItem {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 3rem;
  border-width: 10;
  border-color: whitesmoke;
}

.svg0 {
  height: 150px;
  overflow: hidden;
}
.svg1 {
  height: 100%;
  width: 100%;
}
.svg2 {
  stroke: none;
  fill: #08f;
}
.navItem:hover {
  /* background-color: #003466; */
  height: 3rem;
  color: #212534;
  animation: mymove 2s infinite;
}
@keyframes mymove {
  from {
    background-color: #003466;
  }
  50% {
    background-color: #265e92;
  }
  to {
    background-color: #003466;
  }
}
.active {
  background-color: #dadadc;
  color: #445d75;
  cursor:pointer;
}
.nav-link {
  padding-top: 10px;
  font-size: 18px;
  /* color: white; */
  cursor: pointer;
  font-family: monospace;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}

.nav-link:hover {
  color: white;
}
.nav-link-mobile {
  font-size: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #f7f7f7;
  /* -webkit-text-stroke: 0.2px #003466; */
  /* text-shadow: 2px 2px white; */
  font-weight: bold;
  cursor: pointer;
  font-family: monospace;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile-select {
  font-size: 26px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  -webkit-text-stroke: 0.1px #003466;
  text-shadow: 2px 3px #003466;
  font-weight: bold;
  cursor: pointer;
  font-family: monospace;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile:hover {
  font-size: 26px;
  /* color: white; */
  /* -webkit-text-stroke: 0.1px #003466; */
  /* text-shadow: 2px 3px #003466; */
  background-color: #6095ce52;
  font-weight: bold;
}
.nav-link-close {
  color: white;
  cursor: pointer;
  padding: 1rem;
}
.nav-link-close:hover {
  color: white;
  background-color: #003466;
}
.nav-list {
  text-align: left;
  font-size: 25px;
  position: relative;
  color: #4e80b6;
  font-weight: bold;
  display: block;
  text-decoration: solid;
  -webkit-text-stroke: 0.1px #003466;
  /* text-shadow: 2px 2px #003466; */
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: "Montserrat", sans-serif;
}
.nav-list-list {
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  color: #003466;
  padding: 0%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: "Montserrat", sans-serif;
}
.nav-list-list:hover {
  background-color: #003466;
  color: white;
}
.nav-mobile {
  min-width: 5rem;
}
.nav-list-st {
  clear: both;
  display: inline-block;
  white-space: nowrap;
  padding: 0px;
}
.menuIcon {
  color: white;
}
.searchBoxOpen {
  display: block;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  font-family: "Montserrat", sans-serif;
  border: 1px solid #4e80b6;
  /* padding: 12px 15px; */
}
.searchBoxClose {
  display: none;
}
.menu {
  display: none;
}
.menuList {
  /* min-height: 0rem; */
  background-color: #dadadc;
  overflow-x: auto;
  box-shadow: 3px 3px 5px 2px #54575a99;
}
.mobileMenuList {
  min-height: 7rem;
  max-height: 30rem;
  background-color: #6095cec0;
  overflow-x: auto;
  box-shadow: 3px 3px 5px 2px #a09c9c99;
}
.mobileMenuListList {
  min-height: 7rem;
  background-color: #dbdee6ea;
  border-bottom-style: outset;
  box-shadow: 3px 3px 5px 2px #72757a99;
}
.show1 {
  display: block;
}
.hide {
  display: none;
}
.showMenu {
  height: 450px;
  overflow: hidden;
  transition: height 300ms;
}
.hideMenu {
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}

.arrowUp {
  transition: transform 0.3s linear;
  transform: rotate(180deg);
}

.navHeader {
  height: 5rem;
}
.logo {
  height: 4.5rem;
  position: absolute;
  z-index: -1;
}
.logoTitle {
  padding-left: 20px;
  z-index: -1;
}
.navbar {
  color: white;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #4e80b6;
  height: 3rem;
}
#navbar {
  z-index: 1050;
}
.navbarLight {
  box-shadow: 3px 5px 5px 2px #88888888;
}
.navBarTop {
  position: fixed;
  top: 0;
  width: 100%;
}

.login {
  background-color: #4e80b6;
  font-size: 18px;
  color: white;
  border-radius: 10px;
  z-index: 200;
  width: 7rem;
  box-shadow: 3px 3px 5px 2px #99999999;
}
.loginLay {
  z-index: 200;
  padding-top: 5px;
}
.login:hover {
  background-color: #212534;
  font-size: 20px;
  color: white;
}
.titleTag {
  padding-top: 1.8rem;
  padding-right: 4rem;
}
.titleText {
  font-style: oblique;
  font-weight: 400;
  font-size: 18px;
  font-family: Papyrus, fantasy;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 4px;
}
.titleTextDark {
  font-style: oblique;
  font-weight: 400;
  font-size: 18px;
  font-family: Papyrus, fantasy;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 4px;
  color: #fff;
}
.typewriter h1 {
  overflow: hidden;
  border-right: 0.15em solid rgb(60, 124, 209);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  font-size: 15px;
  animation: typing 7s steps(40, end), blink-caret 0.75s step-end infinite;
  font-family: "Montserrat", sans-serif;
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(60, 124, 209);
  }
}

.navRight {
  float: right;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4c4c4d;
}

.authMenu {
  cursor: pointer;
}

.authMenu:hover {
  background: #4e80b67a;
  color: #fff;
}
.authMenu:active {
  background: #034b8fa9;
}

.authMenu:focus {
  background: #003466;
}
.budgeRole {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  margin-top: -6px;
}
.budgeRoleGreen {
  background: #2ce99a;
  color: #ffffff;
}
.budgeRoleYellow {
  background: #ffc353;
  color: #ffffff;
}
.budgeRoleGray {
  background: #867e7e96;
  color: #ffffff;
}
@media only screen and (min-device-width: 150px) and (max-device-width: 319px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 14px;
    padding-left: 3px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 5rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3rem;
    position: absolute;
    z-index: -1;
    margin-top: 10px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 387px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3.5rem;
    position: absolute;
    z-index: -1;
    margin-top: 0px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 387px) and (max-device-width: 480px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
    margin-top: 0px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 479px) and (max-device-width: 767px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 10rem;
  }
  .loginBtn {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .searchBoxOpen {
    width: 12rem;
  }

  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .searchBoxOpen {
    width: 12rem;
  }

  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 580px) and (min-device-width: 580px) {
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .hideSearch {
    display: none;
  }
}
@media only screen and (max-width: 850px) and (min-device-width: 768px) {
  .loginBtn {
    margin-top: 10px;
  }
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .navbar {
    padding-top: 0%;
  }
  .loginLay {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 992px) and (min-device-width: 768px) {
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 1150px) and (min-width: 992px) {
  .hideSearch {
    display: none;
  }
  .searchBoxOpen {
    width: 12rem;
  }
  .navbar {
    padding-top: 10px;
  }
}
