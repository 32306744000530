@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,800);
@import url(https://fonts.googleapis.com/css2?family=Architects+Daughter&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Mr+Dafoe&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,400;0,600;1,200;1,400;1,600&display=swap);
body {
  margin: 0;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-family: "Montserrat", sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
    font-family: "Montserrat", sans-serif;
}


.App {
  text-align: center;
}
.bgDarkThroout {
  background-color: #212534;
}
.bgPriThroout {
  background-color: #4e80b6;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}
.absolute {
  position: absolute;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgPri {
  background-color: #4e80b6;
}

.bgPriRad {
  background: linear-gradient(to right, #5a8abd, #0d4172);
}
.bgPriRad2 {
  background: linear-gradient(to right, #4e80b6, #5386bb);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid rgba(255, 255, 255, 0.603);
}
.textPri {
  color: #4e80b6;
}
.textPri2 {
  color: #f86e56;
}
.textPriHover {
  color: #4e80b6;
}
.textPriHover:hover {
  color: rgb(29, 51, 117);
}
.borderPri {
  border: 1px solid #4e80b6;
}
.bgSec {
  background-color: rgba(224, 220, 220, 0.603);
}
.textSec {
  color: rgba(224, 220, 220, 0.603);
}
.textDark {
  color: rgba(102, 95, 95, 0.734);
}
.borderSec {
  border: 1px solid rgba(224, 220, 220, 0.603);
}
.pointer {
  cursor: pointer;
}
.disable {
  cursor: not-allowed;
}
.axText {
  font-family: "Montserrat", sans-serif;
}
.loginModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  /* margin: 0px 50px; */
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 1950;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
h2 {
  text-align: center;
}
strong {
  font-weight: 1000 !important;
}
p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 15px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.span2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}

.spanvalidate {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
/* 
button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
} */
.buttonDisabled {
  border: solid 1px #a79e9d !important;
  background-color: transparent !important;
  color: #a79e9d !important;
  cursor: not-allowed !important;
}

.buttonPri {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: #4e80b6;
  color: #fff !important;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonPri:hover {
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6 !important;
}
.buttonPri:active {
  transform: scale(0.95);
}
.buttonPri:focus {
  outline: none;
}

.buttonLight {
  border-radius: 20px;
  border: solid 1px #fcfcfc;
  background-color: #f5f5f5;
  color: #4e80b6;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonLight:hover {
  border: solid 1px #fcfcfc;
  background-color: transparent;
  color: #fcfcfc;
}
.buttonLight:active {
  transform: scale(0.95);
}
.buttonLight:focus {
  outline: none;
}

.buttonLightTran {
  border-radius: 20px;
  border: solid 1px #fcfcfc;
  background-color: transparent;
  color: #fcfcfc;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonLightTran:hover {
  border: solid 1px #fcfcfc;
  background-color: #f5f5f5;
  color: #4e80b6;
}
.buttonLightTran:active {
  transform: scale(0.95);
}
.buttonLightTran:focus {
  outline: none;
}
.buttonDark {
  border-radius: 20px;

  border: solid 0px #fff;
  background: linear-gradient(to right, #928e8e52, rgba(170, 163, 163, 0.707));
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonDark:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px #fff;
  /* color:#616161; */
}
.buttonBlack {
  border-radius: 5px;

  border: solid 0px #fff;
  background: linear-gradient(to right, #1d1d1d52, rgba(131, 131, 131, 0.707));
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonBlack:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.349);
  /* color:#616161; */
}
.rad15 {
  border-radius: 15px;
}
.buttonDark1 {
  border: solid 1px #5f5a5a52;
  color: #5f5a5a52;
  /* background: linear-gradient(to right, #e6e4e4, rgb(235, 231, 231)); */
  background-color: transparent;
}
button.ghost {
  background-color: transparent;
}
button.pri {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6 !important;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
button.pri:hover {
  background-color: #4e80b6 !important;
  border-color: transparent;
  color: #fff !important;
}
button.pri:active {
  transform: scale(0.95);
}
.formlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input.tag {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.answer {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  border-radius:10px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
input.alert {
}
input {
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.effect7 {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect7:before,
.effect7:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-radius: 100px / 10px;
}
.effect7:after {
  right: 10px;
  left: auto;
  transform: skew(8deg) rotate(3deg);
}
.effect8 {
  position: relative;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}
.effect8:before,
.effect8:after {
  content: "";
  position: absolute;
  z-index: -1;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  border-radius: 100px / 10px;
}
.effect8:after {
  right: 10px;
  left: auto;
  /* -webkit-transform:skew(8deg) rotate(3deg);
	   -moz-transform:skew(8deg) rotate(3deg);
	    -ms-transform:skew(8deg) rotate(3deg);
		-o-transform:skew(8deg) rotate(3deg);
		   transform:skew(8deg) rotate(3deg); */
}

.shadow1 {
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.shadow2 {
  box-shadow: 7px 7px 25px rgba(236, 233, 233, 0.871);
}
.box2 {
  box-shadow: 0 2.8px 2.2px rgba(206, 203, 203, 0.034),
    0 6.7px 5.3px rgba(212, 211, 211, 0.048),
    0 12.5px 10px rgba(228, 227, 227, 0.06),
    0 22.3px 17.9px rgba(194, 191, 191, 0.072),
    0 41.8px 33.4px rgba(206, 205, 205, 0.086),
    0 100px 80px rgba(230, 228, 228, 0.12);
}
.se-toolbar {
  background-color: rgba(230, 233, 238, 0.912) !important;
  /* border: 1px solid rgb(228, 228, 228) !important; */
}
.se-menu-list {
  background-color: rgba(214, 217, 223, 0.994) !important;
  /* border: 1px solid rgb(201, 199, 199) !important; */
}
.se-btn:hover {
  background-color: rgba(242, 243, 243, 0.768) !important;
}
.se-btn.active {
  background-color: #003466 !important;
  color: rgb(144, 199, 250) !important;
}

@font-face {
  font-family: "Bamini";
  font-style: normal;
  font-weight: 5;
  src: local("Bamini"), url(/static/media/Bamini.80984890.woff) format("woff");
}
@font-face {
  font-family: "SU Sewandi";
  font-style: normal;
  font-weight: 5;
  src: local("SU Sewandi"), url("/static/media/SU Sewandi.27d02a40.ttf") format("truetype");
}
@font-face {
  font-family: "DL-Sarala";
  font-style: normal;
  font-weight: 5;
  src: local("DL-Sarala"), url(/static/media/DL-Sarala.a8b1494d.TTF) format("truetype");
}
@font-face {
  font-family: "Kusum";
  font-style: normal;
  font-weight: 5;
  src: local("Kusum"), url(/static/media/Kusum.7c1bda16.ttf) format("truetype");
}
@media only screen and (min-width: 300px) and (max-width: 850px) {
  .buttonPri {
    font-size: 10px;

    padding: 8px 30px;
  }
}

.sun-editor-editable table {
  /* display: table;
     table-layout: auto !important;
     border: 1px solid #ccc;
     width: 100%;
     max-width: 100%;
     margin: 0 0 10px;
     background-color: transparent;
     border-spacing: 0;
     border-collapse: collapse; */
}
.learnerDashStyle .sun-editor {
  margin-top: 60px;
  margin-bottom: 20px;
  border: none;
}
.learnerDashStyleDark .sun-editor {
  margin-top: 60px;
  margin-bottom: 20px;
  border: none;
}
.contentStyle .sun-editor {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent !important;
}
.contentStyleDark .sun-editor {
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  background-color: transparent !important;
  color: #fff !important;
}
.contentStyleDark .sun-editor-editable {
  background-color: transparent !important;
  color: #fff !important;
}
.contentStyle .sun-editor-editable {
  background-color: transparent !important;
}
.sun-editor-editable hr {
  border-top: 1px solid rgb(255, 255, 255) !important;
}
.contentStyleDark .sun-editor-editable[contenteditable="true"] .se-component {
  outline: none;
}
.previewQuestion .sun-editor {
  margin-top: 20px;
  margin-bottom: -20px;
  border: none;
  font-size: 26px !important;
  background-color: transparent !important;
}
.previewQuestion .sun-editor-editable {
  background-color: transparent !important;
  font-size: 18px !important;
}

.previewQuestionDark .sun-editor {
  margin-top: 20px;
  margin-bottom: -20px;
  border: none;
  font-size: 26px !important;
 
  background-color: transparent !important;
}
.previewQuestionDark .sun-editor-editable {
  background-color: transparent !important;
  font-size: 18px !important;
  color: #fff !important;
}
@media only screen and (min-width: 00px) and (max-width: 500px) {
  .contentStyle .sun-editor {
    font-size: 16px !important;
    line-height: 35px !important;
  }
  .contentStyleDark .sun-editor {
    font-size: 16px !important;
  }
  .sun-editor span {
    font-size: 17px !important;
  }
  .sun-editor h1 {
    font-size: 19px !important;
  }
  .buttonNext {
    border: none !important;
    background-color: #4e80b67e !important;
    padding: 4px 10px !important;
  }
  .buttonNext:hover:before {
    content: "" !important;
  }

  .buttonPrevious {
    border: none !important;
    background-color: #4e80b67e !important;
    padding: 4px 10px !important;
  }

  .buttonPrevious:hover:after {
    content: "" !important;
  }
  .sun-editor-editable {
    padding: 7px !important;
    -webkit-margin-before: 0.27em !important;
            margin-block-start: 0.27em !important;
    -webkit-margin-after: 0.27em !important;
            margin-block-end: 0.27em !important;
  }
  .sun-editor-editable h1 {
    padding: 7px !important;
    -webkit-margin-before: 0.27em !important;
            margin-block-start: 0.27em !important;
    -webkit-margin-after: 0.27em !important;
            margin-block-end: 0.27em !important;
  }
}




.avatar {
  height: 35px;
  width: 35px;
  border-radius: 15px;
  margin-left: 0px;
  border: 1px solid #fff;
}

.navItem {
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  height: 3rem;
  border-width: 10;
  border-color: whitesmoke;
}

.svg0 {
  height: 150px;
  overflow: hidden;
}
.svg1 {
  height: 100%;
  width: 100%;
}
.svg2 {
  stroke: none;
  fill: #08f;
}
.navItem:hover {
  /* background-color: #003466; */
  height: 3rem;
  color: #212534;
  -webkit-animation: mymove 2s infinite;
          animation: mymove 2s infinite;
}
@-webkit-keyframes mymove {
  from {
    background-color: #003466;
  }
  50% {
    background-color: #265e92;
  }
  to {
    background-color: #003466;
  }
}
@keyframes mymove {
  from {
    background-color: #003466;
  }
  50% {
    background-color: #265e92;
  }
  to {
    background-color: #003466;
  }
}
.active {
  background-color: #dadadc;
  color: #445d75;
  cursor:pointer;
}
.nav-link {
  padding-top: 10px;
  font-size: 18px;
  /* color: white; */
  cursor: pointer;
  font-family: monospace;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}

.nav-link:hover {
  color: white;
}
.nav-link-mobile {
  font-size: 20px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #f7f7f7;
  /* -webkit-text-stroke: 0.2px #003466; */
  /* text-shadow: 2px 2px white; */
  font-weight: bold;
  cursor: pointer;
  font-family: monospace;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile-select {
  font-size: 26px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
  -webkit-text-stroke: 0.1px #003466;
  text-shadow: 2px 3px #003466;
  font-weight: bold;
  cursor: pointer;
  font-family: monospace;
  font-family: "Montserrat", sans-serif;
}
.nav-link-mobile:hover {
  font-size: 26px;
  /* color: white; */
  /* -webkit-text-stroke: 0.1px #003466; */
  /* text-shadow: 2px 3px #003466; */
  background-color: #6095ce52;
  font-weight: bold;
}
.nav-link-close {
  color: white;
  cursor: pointer;
  padding: 1rem;
}
.nav-link-close:hover {
  color: white;
  background-color: #003466;
}
.nav-list {
  text-align: left;
  font-size: 25px;
  position: relative;
  color: #4e80b6;
  font-weight: bold;
  display: block;
  -webkit-text-decoration: solid;
          text-decoration: solid;
  -webkit-text-stroke: 0.1px #003466;
  /* text-shadow: 2px 2px #003466; */
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: "Montserrat", sans-serif;
}
.nav-list-list {
  text-align: left;
  font-size: 18px;
  cursor: pointer;
  color: #003466;
  padding: 0%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-family: "Montserrat", sans-serif;
}
.nav-list-list:hover {
  background-color: #003466;
  color: white;
}
.nav-mobile {
  min-width: 5rem;
}
.nav-list-st {
  clear: both;
  display: inline-block;
  white-space: nowrap;
  padding: 0px;
}
.menuIcon {
  color: white;
}
.searchBoxOpen {
  display: block;
  /* border-top-left-radius: 10px;
  border-bottom-left-radius: 10px; */
  font-family: "Montserrat", sans-serif;
  border: 1px solid #4e80b6;
  /* padding: 12px 15px; */
}
.searchBoxClose {
  display: none;
}
.menu {
  display: none;
}
.menuList {
  /* min-height: 0rem; */
  background-color: #dadadc;
  overflow-x: auto;
  box-shadow: 3px 3px 5px 2px #54575a99;
}
.mobileMenuList {
  min-height: 7rem;
  max-height: 30rem;
  background-color: #6095cec0;
  overflow-x: auto;
  box-shadow: 3px 3px 5px 2px #a09c9c99;
}
.mobileMenuListList {
  min-height: 7rem;
  background-color: #dbdee6ea;
  border-bottom-style: outset;
  box-shadow: 3px 3px 5px 2px #72757a99;
}
.show1 {
  display: block;
}
.hide {
  display: none;
}
.showMenu {
  height: 450px;
  overflow: hidden;
  transition: height 300ms;
}
.hideMenu {
  height: 0px;
  overflow: hidden;
  transition: height 300ms;
}

.arrowUp {
  transition: transform 0.3s linear;
  transform: rotate(180deg);
}

.navHeader {
  height: 5rem;
}
.logo {
  height: 4.5rem;
  position: absolute;
  z-index: -1;
}
.logoTitle {
  padding-left: 20px;
  z-index: -1;
}
.navbar {
  color: white;
  padding-left: 0px;
  padding-right: 0px;
  background-color: #4e80b6;
  height: 3rem;
}
#navbar {
  z-index: 1050;
}
.navbarLight {
  box-shadow: 3px 5px 5px 2px #88888888;
}
.navBarTop {
  position: fixed;
  top: 0;
  width: 100%;
}

.login {
  background-color: #4e80b6;
  font-size: 18px;
  color: white;
  border-radius: 10px;
  z-index: 200;
  width: 7rem;
  box-shadow: 3px 3px 5px 2px #99999999;
}
.loginLay {
  z-index: 200;
  padding-top: 5px;
}
.login:hover {
  background-color: #212534;
  font-size: 20px;
  color: white;
}
.titleTag {
  padding-top: 1.8rem;
  padding-right: 4rem;
}
.titleText {
  font-style: oblique;
  font-weight: 400;
  font-size: 18px;
  font-family: Papyrus, fantasy;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 4px;
}
.titleTextDark {
  font-style: oblique;
  font-weight: 400;
  font-size: 18px;
  font-family: Papyrus, fantasy;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 4px;
  color: #fff;
}
.typewriter h1 {
  overflow: hidden;
  border-right: 0.15em solid rgb(60, 124, 209);
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: 0.15em;
  font-size: 15px;
  -webkit-animation: typing 7s steps(40, end), blink-caret 0.75s step-end infinite;
          animation: typing 7s steps(40, end), blink-caret 0.75s step-end infinite;
  font-family: "Montserrat", sans-serif;
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
@-webkit-keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(60, 124, 209);
  }
}
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: rgb(60, 124, 209);
  }
}

.navRight {
  float: right;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background: transparent;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #4c4c4d;
}

.authMenu {
  cursor: pointer;
}

.authMenu:hover {
  background: #4e80b67a;
  color: #fff;
}
.authMenu:active {
  background: #034b8fa9;
}

.authMenu:focus {
  background: #003466;
}
.budgeRole {
  box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
  margin-top: -6px;
}
.budgeRoleGreen {
  background: #2ce99a;
  color: #ffffff;
}
.budgeRoleYellow {
  background: #ffc353;
  color: #ffffff;
}
.budgeRoleGray {
  background: #867e7e96;
  color: #ffffff;
}
@media only screen and (min-device-width: 150px) and (max-device-width: 319px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 14px;
    padding-left: 3px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 5rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 320px) and (max-device-width: 360px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3rem;
    position: absolute;
    z-index: -1;
    margin-top: 10px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 360px) and (max-device-width: 387px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }

  .titleTag {
    display: none;
  }
  .logo {
    height: 3.5rem;
    position: absolute;
    z-index: -1;
    margin-top: 0px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 387px) and (max-device-width: 480px) {
  .budgeRole {
    margin-top: 37px;
    right:10px;
    float: left;
    display: none;
  }
  .headUserName {
    display: none;
  }
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
    margin-top: 0px;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 8rem;
  }
  .hideSearch {
    display: none;
  }
  .loginBtn {
    margin-top: 10px;
  }
}
@media only screen and (min-device-width: 479px) and (max-device-width: 767px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .searchBoxOpen {
    width: 10rem;
  }
  .loginBtn {
    margin-top: 10px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .searchBoxOpen {
    width: 12rem;
  }

  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .loginLay {
    padding-top: 0px;
  }
  .navbar {
    padding-top: 0%;
  }
  .searchBoxOpen {
    width: 12rem;
  }

  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 580px) and (min-device-width: 580px) {
  .navbar {
    padding-top: 0%;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
  .hideSearch {
    display: none;
  }
}
@media only screen and (max-width: 850px) and (min-device-width: 768px) {
  .loginBtn {
    margin-top: 10px;
  }
  .titleTag {
    display: none;
  }
  .logo {
    height: 4rem;
    position: absolute;
    z-index: -1;
  }
  .logoTitle {
    padding-top: 3px;
    padding-left: 5px;
    z-index: -1;
  }
  .navbar {
    padding-top: 0%;
  }
  .loginLay {
    padding-top: 0px;
  }
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 992px) and (min-device-width: 768px) {
  .menu {
    display: block;
  }
  .menuItem {
    display: none;
  }
}
@media only screen and (max-width: 1150px) and (min-width: 992px) {
  .hideSearch {
    display: none;
  }
  .searchBoxOpen {
    width: 12rem;
  }
  .navbar {
    padding-top: 10px;
  }
}

.modal-backdrop {
  background-color: #151925;
}
.loginModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;

  margin: 0px -40px;
  padding: 0px;
}
.logoSignIn {
  margin-top: -350px;
}
.absolute {
  display: absolute;
}
.loginModal-mobile {
  border-radius: 10px;
  box-shadow: 8px 1px 28px rgba(7, 55, 66, 0.25),
    0 10px 10px rgba(7, 26, 41, 0.295);
  background: linear-gradient(to right, #fafcffd8, #76a3e6d7);
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 530px;

  margin-top: 90px;
  display: none;
}
#container {
  /* margin-top:100px; */
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  text-decoration: none;
}
.logo:hover {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: bold;
  font-weight: 1950;
  text-decoration: none;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
h2 {
  text-align: center;
}

p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}

.span1 {
  font-size: 20px;
  line-height: 30px;
}
.spanvalidate {
  font-size: 12px;
  align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
/* 
button {
	border-radius: 20px;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	
}

button:active {
	transform: scale(0.95);
}

button:focus {
	outline: none;
} */

button.ghost {
  background-color: transparent;
  border-color: #fff0ed;
  color: #fff0ed;
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
button.pri {
}
button.pri:hover {
  background-color: transparent;
  border-color: #fff0ed;
  color: #4e80b6;
}
button.pri:active {
  transform: scale(0.95);
}
.formlogin {
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input.tag {
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: rgb(238, 233, 233);
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.alert {
  border: 1px solid #ff4b2b;
}
input.toggle {
}
.term {
  color: #868b8b;
}
.term:hover {
  color: #4e80b6;
  cursor: pointer;
}
.container {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 8px 1px 28px rgba(219, 233, 236, 0.25),
    0 10px 10px rgba(135, 141, 146, 0.295);
  position: relative;
  overflow: hidden;
  width: 868px;
  max-width: 100%;
  min-height: 520px;
}
.container-mobile {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  /* overflow: hidden; */
  width: 868px;
  max-width: 100%;
  /* min-height: 630px; */
}
.form-container {
  position: absolute;
  top: 0;
  height: 100%;
  transition: all 0.6s ease-in-out;
}

.sign-in-container {
  left: 0;
  width: 50%;
  z-index: 2;
}
.sign-in-container-mobile {
  top: 0;
  width: 100%;
  z-index: 2;
}
.container.right-panel-active .sign-in-container {
  transform: translateX(100%);
}

.sign-up-container {
  left: 0;
  width: 50%;
  opacity: 0;
  z-index: 1;
  padding-top: 30px;
}
.sign-up-container-mobile {
  top: 0;
  width: 100%;
  z-index: 1;
}
.container.right-panel-active .sign-up-container {
  transform: translateX(100%);
  opacity: 1;
  z-index: 5;
  -webkit-animation: show 0.6s;
          animation: show 0.6s;
}

@-webkit-keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

@keyframes show {
  0%,
  49.99% {
    opacity: 0;
    z-index: 1;
  }

  50%,
  100% {
    opacity: 1;
    z-index: 5;
  }
}

.overlay-container {
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;
  overflow: hidden;
  transition: transform 0.6s ease-in-out;
  z-index: 100;
}

.container.right-panel-active .overlay-container {
  transform: translateX(-100%);
}

.overlay {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #ffffff;
  position: relative;
  left: -100%;
  height: 100%;
  width: 200%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.container.right-panel-active .overlay {
  transform: translateX(50%);
}

.overlay-panel {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 40px;
  text-align: center;
  top: 0;
  height: 100%;
  width: 50%;
  transform: translateX(0);
  transition: transform 0.6s ease-in-out;
}

.overlay-left {
  transform: translateX(-20%);
}

.container.right-panel-active .overlay-left {
  transform: translateX(0);
}

.overlay-right {
  right: 0;
  transform: translateX(0);
}

.container.right-panel-active .overlay-right {
  transform: translateX(20%);
}

.social-container {
  margin: 20px 0;
}

.social-container a {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  height: 40px;
  width: 40px;
}
.passDropHead {
  background-color: #cfd6d6;
}
.passDropContent {
  background-color: #e4e9e9;
}
/* 
footer {
    background-color: #222;
    color: #fff;
    font-size: 14px;
    bottom: 0;
    position: fixed;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 999;
}

footer p {
    margin: 10px 0;
}

footer i {
    color: red;
}

footer a {
    color: #3c97bf;
    text-decoration: none;
} */

/* toggle */

.switch {
  position: relative;
  display: inline-block;
  width: 120px;

  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #79afcc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #c9c9c9;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  transform: translateX(85px);
}

/*------ ADDED CSS ---------*/

.on1 {
  display: none;
}

.on1,
.off1 {
  color: rgb(31, 31, 31);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider .on1 {
  display: block;
}

input:checked + .slider .off1 {
  display: none;
}

/*--------- END --------*/

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
.ticTerm1 {
  display: none;
}
@media only screen and (min-device-width: 790px) and (max-device-width: 980px) {
  h1.loginHead {
    font-size: 29px;
  }
  .sign-up-container {
    padding-top: 40px;
  }
  .loginModal {
    margin: 0px -100px;
  }
}
@media only screen and (min-device-width: 630px) and (max-device-width: 790px) {
  .logoSignIn {
    margin-top: -250px;
  }
  .sign-up-container {
    padding-top: 10px;
  }
  .loginModal {
    /* margin: 20px 50px; */
    margin: 0px -60px;
    margin-top: 20px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 8px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 360px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 26px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(52px);
  }
}
@media only screen and (min-device-width: 520px) and (max-device-width: 630px) {
  .logoSignIn {
    margin-top: -250px;
    width: 180px;
    height: 50px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  .loginModal {
    margin: 0px 0px;
    margin-top: 15px;
  }
  a {
    font-size: 10px;
  }
  button {
    padding: 6px 20px;
    font-size: 8px;
  }
  p {
    /* font-size: 12px;	 */
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 340px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(41px);
  }
  .ticTerm {
    display: none;
  }
  .ticTerm1 {
    display: block;
  }
}

@media only screen and (min-device-width: 100px) and (max-device-width: 520px) {
  .loginModal-mobile {
    display: block;
  }
  .loginModal {
    margin: 40px 40px;
    display: none;
  }
  h1.loginHead {
    font-size: 19px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 8px;
  }
  button {
    padding: 5px 17px;
    font-size: 5px;
  }
  p {
    /* font-size: 10px;	 */
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 17px;
    /* margin-left:-10px; */
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(43px);
  }
  .ticTerm {
    display: none;
  }
  .ticTerm1 {
    display: block;
  }
}
.loginModalClose {
  margin-top: -150px;
  margin-bottom: 140px;
  margin-right: -300px;
  font-size: 12px;
  color: #7cafe6;
}
@media only screen and (min-width: 991px) and (max-width: 5500px) {
  .loginModalClose {
    margin-top: -130px;
    margin-bottom: 160px;
    margin-right: -400px;
  }
}
@media only screen and (min-width: 981px) and (max-width: 991px) {
  .loginModalClose {
    margin-top: -100px;
    margin-bottom: 140px;
    margin-right: -250px;
  }
}
@media only screen and (min-width: 781px) and (max-width: 981px) {
  .loginModalClose {
    margin-top: -150px;
    margin-bottom: 140px;
    margin-right: -300px;
  }
}
@media only screen and (min-width: 630px) and (max-width: 781px) {
  .loginModalClose {
    margin-top: -70px;
    margin-bottom: 80px;
    margin-right: -270px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  a.logo {
    font-size: 20px;
  }
  button {
    padding: 8px 25px;
    font-size: 8px;
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 6px 0 !important;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 10px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 360px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 80px;
    height: 26px;
  }

  .slider:before {
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 3px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(52px);
  }
}
@media only screen and (min-width: 520px) and (max-width: 630px) {
  .loginModalClose {
    margin-top: -60px;
    margin-bottom: 75px;
    margin-right: -220px;
  }
  h1.loginHead {
    font-size: 20px;
  }
  .sign-up-container {
    padding-top: 10px;
  }
  a.logo {
    font-size: 20px;
  }
  a {
    font-size: 10px;
  }
  button {
    padding: 6px 20px;
    font-size: 8px;
  }
  p {
    /* font-size: 12px;	 */
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 12px 0;
  }
  input.tag {
    padding: 9px 11px;
    margin: 5px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 340px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 20px;
  }

  .slider:before {
    height: 15px;
    width: 15px;
    left: 2px;
    bottom: 3px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 7px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(41px);
  }
  .ticTerm {
    display: none;
  }
  .ticTerm1 {
    display: block;
  }
}

@media only screen and (min-width: 430px) and (max-width: 520px) {
  .loginModalClose {
    margin-top: -30px;
    margin-right: -10px;
  }
  .loginModal-mobile {
    margin: 50px 50px;
    padding: 40px 20px;
    display: block;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 25px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 8px;
  }
  button {
    padding: 5px 17px;
    font-size: 5px;
  }
  p {
    /* font-size: 10px;	 */
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 17px;
    /* margin-left:-10px; */
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(43px);
  }
  .ticTerm {
    display: none;
  }
  .ticTerm1 {
    display: block;
  }
}
@media only screen and (min-width: 100px) and (max-width: 430px) {
  .loginModalClose {
    margin-top: -10px;
    margin-right: 10px;
  }
  .loginModal-mobile {
    margin: 30px 10px;
    padding: 20px 0px;
    display: block;
  }
  .loginModal {
    display: none;
  }
  h1.loginHead {
    font-size: 25px;
  }
  a.logo {
    font-size: 15px;
  }
  a {
    font-size: 8px;
  }
  button {
    padding: 5px 17px;
    font-size: 5px;
  }
  p {
    /* font-size: 10px;	 */
  }
  .formlogin {
    padding: 0 20px;
  }
  .overlay-panel {
    padding: 0 20px;
  }
  .social-container a {
    height: 25px;
    width: 25px;
  }
  .social-container {
    margin: 5px 0;
  }
  input.tag {
    padding: 7px 9px;
    margin: 3px 0;
    width: 100%;
    font-size: 8px;
  }
  input.toggle {
    /* padding: 9px 11px;
		margin: 5px 0;
		width: 100%; */
    font-size: 10px;
  }
  .container {
    min-height: 380px;
  }
  span {
    font-size: 12px;
    /* line-height: 30px; */
  }
  .switch {
    width: 60px;
    height: 17px;
    /* margin-left:-10px; */
  }

  .slider:before {
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
  }
  .on1,
  .off1 {
    top: 60%;
    left: 50%;
    font-size: 6px;
    font-family: Verdana, sans-serif;
  }
  input:checked + .slider:before {
    transform: translateX(43px);
  }
  .ticTerm {
    display: none;
  }
  .ticTerm1 {
    display: block;
  }
}

.loader3,
.loader3:before,
.loader3:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader3Head {
  padding-top: 1px;
}
.loader3 {
  color: #4e80b6;
  font-size: 4px;
  margin: 0px auto;
  margin-top: 45px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  z-index:1500;

}
.loader3:before,
.loader3:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader3:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader3:after {
  left: 3.5em;
}

.loader3White,
.loader3White:before,
.loader3White:after {
  border-radius: 50%;
  width: 2em;
  height: 2em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
}
.loader3WhiteHead {
  padding-top: 1px;
}
.loader3White {
  z-index:1500;
  color: #fff;
  font-size: 4px;
  margin: 0px auto;
  margin-top: -45px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader3White:before,
.loader3White:after {
  content: "";
  position: absolute;
  top: 0;
}
.loader3White:before {
  left: -3.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader3White:after {
  left: 3.5em;
}

@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }
  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.dropDownSelect {
  background-color: rgba(225, 231, 238, 0.897);
  border: 1px solid #fff;
  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}

.dropDownSelectDark {
  background-color: rgba(241, 243, 245, 0.768);
  border: none;


  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
  color: #000;
}
.input-group-append{
  font-size: 12px ;
  margin: 8px 0 ;
}
.reviewInputNumber{
  margin-top: 0px;
 height:40px;
}
.textInput {
  border: 1px solid #fff !important;
  background-color: rgba(225, 231, 238, 0.768);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px !important;
  margin: 8px 0 !important;
  width: 100%;
  /* min-width: 240px;
     max-width: 340px; */

  /* padding-right:50px; */
  font-size: 12px ;
}
.reviewMatch{
  height:40px !important;
  margin-bottom: 10px !important;
}
.reviewInput{
 height:40px !important;
}
.textInput1{
  border: 1px solid #fff !important;
  background-color: rgba(225, 231, 238, 0.768);

  font-family: "Montserrat", sans-serif;
  padding: 10px 15px !important;
  margin: 8px 0 !important;

  font-size: 12px ;
}

.textInputDark {
  border: 1px solid #fff !important;
  background-color: rgba(241, 243, 245, 0.768);
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px;
  margin: 8px 0 ;
  width: 100%;
  color: #000;
  font-size: 12px;
}

.textInputDark1 {
  border: 1px solid #fff !important;
  background-color: rgba(241, 243, 245, 0.768);

  font-family: "Montserrat", sans-serif;
  padding: 10px 15px ;
  margin: 8px 0 !important;
  color: #000;
  font-size: 12px;
}
.createContentStyle {
  padding: 4%;
  margin: 0%;
  width: 100%;
  padding-top: 3%;
}
.createContentStyleDark {
  padding: 4%;
  margin: 0%;
  width: 100%;
  padding-top: 3%;
  color: #fff;
}
.createContentLabel {
  color: #000;
}
.createContentLabelDark {
  color: #b1c9e2;
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .title {
    font-size: 29px;
    padding-top: 25px;
  }
}
@media only screen and (min-width: 220px) and (max-width: 400px) {
  .title {
    font-size: 25px;
    padding-top: 25px;
  }
  .dropDownSelect {
    margin: 8px 0;
    font-size: 12px;
  }
  .dropDownSelectDark {
    margin: 8px 0;
    font-size: 12px;
  }
  .textInput {
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 12px;
  }
  .textInputDark {
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 12px;
  }
  .createContentStyle {
    padding: 4%;
    padding-top: 3%;
  }
  .createContentStyleDark {
    padding: 4%;
    padding-top: 3%;
  }
  .createContentLabel {
    font-size: 13px;
  }
  .createContentLabelDark {
    font-size: 13px;
  }
}

.bg-morning{
  background-color: #7fb1e6c4;
  height:100%;
}
.bg-evening{
  background-color: #e7bd3494;
  height:100%;
}
.bg-night{
  background-color: #0c376894;
  height:100%;
}
.text-success1{
  color: #65fc51;
}
.bg-success1{
  color: #313131;
  background-color: #65fc51;
}
.learnerDashStyle {
  /* padding-left: 4%; */
  margin: 0%;
  padding-top: 1%;
  width: 100%;
  /* background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.93),
    rgba(255, 255, 255, 0.93)
  ); */
  margin-bottom: 50%;
  /* url("../../images/imageBack2.jpg"); */
}

.learnerDashStyleDark {
  width: 100%;

  margin: 0%;
  padding-top: 1%;
  color: #fff;
  margin-bottom: 50%;
  /* background-image: linear-gradient(
    to bottom,
    rgba(33, 37, 52, 0.946),
    rgba(33, 37, 52, 0.953)
  ); */
  /* url("../../images/imageBack2.jpg"); */
}

.calOpen {
  -webkit-animation: calOpen 2s;
          animation: calOpen 2s;
  height: 420px;
  /* opacity: 1; */
}
@-webkit-keyframes calOpen {
  from {
    height: 70px;
  }

  to {
    height: 420px;
  }
}
@keyframes calOpen {
  from {
    height: 70px;
  }

  to {
    height: 420px;
  }
}
.calClose {
  -webkit-animation: calClose 2s;
          animation: calClose 2s;
  height: 70px;
  /* opacity: 1; */
}
@-webkit-keyframes calClose {
  from {
    height: 420px;
  }

  to {
    height: 70px;
  }
}
@keyframes calClose {
  from {
    height: 420px;
  }

  to {
    height: 70px;
  }
}
.progress-bar {
  background-color: #2d5d91;
}
.classSquareHead1 {
  color: #2d5d91;
  padding: 0px;
  margin: 0px;
  padding-top: 9px;
  font-size: 18px;
}
.classSquareHead1Dark {
  color: #d5d5da;
  padding: 0px;
  margin: 0px;
  padding-top: 9px;
  font-size: 18px;
}
.InstructorName {
  background-color: #4e80b6;
  color: #fff;
  cursor: pointer;
}

.InstructorName1:hover .InstructorName {
  background-color: #fff;
  color: #4e80b6;
}
.InstructorName1:hover {
  background: #4e80b67a;
  color: #fff;
}
.InstructorName1:active {
  background: #034b8fa9;
}

.InstructorName1:focus {
  background: #003466;
}
.classBox {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #6b9ed4;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 520px;
}
.classBoxDark {
  border-radius: 10px;
  border: 1px solid #97b5d648;
  border-top: 6px solid #6b9ed4;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  min-width: 320px;
  max-width: 520px;
  background: #3e62798a;
}
.classModal {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
  background: rgba(255, 255, 255, 0.938);
}
.classModalDark {
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;

  background: #3e6279e1;
}
.classBoxImage {
  border-radius: 35px;

  margin-top: 9px;
  margin-left: 9px;
  margin-bottom: 9px;
  padding-top: 0px;
  border: 1px solid #7e9cbb;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 45px;
}
.pointImage {
  margin-top: 9px;
  margin-bottom: 9px;
  padding-top: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 55px;
}
.angleImage {
  margin-top: 15px;

  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 45px;
  border-radius: 15px;
}
.folderImage {
  margin-top: 5px;
  margin-bottom: 5px;
  padding-top: 0px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 65px;
}
@-webkit-keyframes heart1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes heart1 {
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.1);
  }
  30% {
    transform: scale(1);
  }
  40% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.justImage {
  -webkit-animation: heart1 2.5s infinite alternate;
          animation: heart1 2.5s infinite alternate;
  position: absolute;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
  height: 50px;
}
.leftDashContainer {
  width: 25%;
}
.rightDashContainer {
  width: 75%;
}
.bg-throout {
  background-color: #4e80b6;
  color: #fff;
}
.mobile-bottom-nav {
  position: fixed;
  bottom: -50px;
  left: 0;
  right: 0;
  z-index: 3000;

  will-change: transform;
  transform: translateZ(0);

  display: flex;

  height: 60px;
  /* width: 100%; */
  /* box-shadow: 0 -2px 5px -2px #333; */
  /* background-color: #fff; */
}
.navBottom {
  padding: 0px;
  margin: 0px;
  margin-top: 10px;
  width: 100%;
  justify-content: flex-end;
}
.navBottom1 {
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin: 0px;
  margin-top: -50px;
  height: 50px;
  width: 360px;
  /* border-radius: 50px; */
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-color: #4e80b6b8;
  z-index: 1052 !important;
}
#navBottom2 {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  margin-right: 10px;
  position: absolute;
  /* margin:10px; */
  height: 500px;
  width: 340px;
  border-radius: 10px;
  background-color: #4e80b6;
  z-index: 1051;
}
.navBottom2Up {
  -webkit-animation: navmove 2s;
          animation: navmove 2s;
  margin-top: -620px;
  opacity: 1;
}
@-webkit-keyframes navmove {
  from {
    opacity: 1;
    margin-top: 0px;
  }

  to {
    opacity: 1;
    margin-top: -620px;
  }
}
@keyframes navmove {
  from {
    opacity: 1;
    margin-top: 0px;
  }

  to {
    opacity: 1;
    margin-top: -620px;
  }
}

.navBottom2Down {
  -webkit-animation: navmove1 2s;
          animation: navmove1 2s;
  margin-top: 0px;
  opacity: 0;
}
@-webkit-keyframes navmove1 {
  from {
    margin-top: -620px;
    opacity: 1;
  }
  99% {
    opacity: 1;
    margin-top: 1px;
  }
  to {
    opacity: 0;
    margin-top: 0px;
  }
}
@keyframes navmove1 {
  from {
    margin-top: -620px;
    opacity: 1;
  }
  99% {
    opacity: 1;
    margin-top: 1px;
  }
  to {
    opacity: 0;
    margin-top: 0px;
  }
}
.navBottom3 {
  overflow-y: scroll;
  height: 570px;
  background-color: #f7f7f7;
}
.navBottom3Dark {
  overflow-y: scroll;
  height: 570px;
  background-color: rgba(33, 37, 52, 0.946);
}

.navBottom3::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.navBottom3::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}

.navBottom3Dark::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.navBottom3Dark::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
.navBottom3Dark::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
.navBottom3::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}
.navBottomText {
  color: #fff;
  font-size: 19px;
  margin-top: 16px;
  padding-left: 18px !important;
  font-weight: 600 !important;
  float: left;
}
.navBottomIcon {
  float: right;
  color: #fff;
  font-size: 19px;
  margin-top: 14px;
  cursor: pointer;
  padding-right: 18px !important;
  font-weight: 600 !important;
}
.navBottom1 img {
  width: 40px;
  border: 1px solid #fff;
  border-radius: 50%;
  margin-left: 5px;
  margin-top: 5px;
  float: left;
}
.navCalender {
  display: none;
}
.rotate {
  transform: rotate(-180deg);
  transform-origin: 20% 60%;
}

.icon-rotate {
  transition-duration: 0.7s;
  /* display: inline-block; */
}
@media only screen and (min-width: 1930px) and (max-width: 3050px) {
  .leftDashContainer {
    width: 20%;
  }
  .rightDashContainer {
    width: 80%;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1290px) {
  .leftDashContainer {
    width: 32%;
  }
  .rightDashContainer {
    width: 67%;
  }
  .container-calendar {
    min-width: 200px;
  }
}
@media only screen and (min-width: 200px) and (max-width: 1000px) {
  .leftDashContainer {
    width: 0%;
    display: none;
  }
  .rightDashContainer {
    width: 100%;
    padding: 0px !important;
    margin: 0px !important;
  }
  #noticeBoard {
    margin-right: 0 !important;
  }
  .navCalender {
    display: block;
  }
}

@media only screen and (min-width: 200px) and (max-width: 600px) {
  .navBottom {
    justify-content: center;
  }
}

@media only screen and (min-height: 200px) and (max-height: 300px) {
  #navBottom2 {
    height: 250px !important;
  }
}
@media only screen and (min-height: 300px) and (max-height: 400px) {
  #chatview {
    height: 223px !important;
  }
  #navBottom2 {
    height: 272px !important;
  }

  .navBottom3 {
    height: 272px !important;
  }
  .navBottom3Dark {
    height: 272px !important;
  }
  .p1 #profile {
    height: 60px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 223px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 210px !important;
  }
  #friends {
    height: 160px !important;
  }
  .navBottom2Up {
    -webkit-animation: navmove 2s;
            animation: navmove 2s;
    margin-top: -320px;
    opacity: 1;
  }
  @-webkit-keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -320px;
    }
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -320px;
    }
  }

  .navBottom2Down {
    -webkit-animation: navmove1 2s;
            animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @-webkit-keyframes navmove1 {
    from {
      margin-top: -320px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  @keyframes navmove1 {
    from {
      margin-top: -320px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 105px !important;
  }
}
@media only screen and (min-height: 400px) and (max-height: 500px) {
  #chatview {
    height: 323px !important;
  }
  #navBottom2 {
    height: 372px !important;
  }
  .navBottom3 {
    height: 372px !important;
  }
  .navBottom3Dark {
    height: 372px !important;
  }
  .p1 #profile {
    height: 65px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 320px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 310px !important;
  }
  #friends {
    height: 260px !important;
  }
  .navBottom2Up {
    -webkit-animation: navmove 2s;
            animation: navmove 2s;
    margin-top: -420px;
    opacity: 1;
  }
  @-webkit-keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -420px;
    }
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -420px;
    }
  }

  .navBottom2Down {
    -webkit-animation: navmove1 2s;
            animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @-webkit-keyframes navmove1 {
    from {
      margin-top: -420px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  @keyframes navmove1 {
    from {
      margin-top: -420px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 200px !important;
  }
}
@media only screen and (min-height: 500px) and (max-height: 600px) {
  #chatview {
    height: 423px !important;
  }
  #navBottom2 {
    height: 472px !important;
  }
  .navBottom3 {
    height: 472px !important;
  }
  .navBottom3Dark {
    height: 472px !important;
  }
  .p1 #profile {
    height: 65px !important;
    border-radius: 0px;
  }
  #search {
    bottom: 2px !important;
  }
  .chatSetting {
    height: 420px !important;
    overflow-y: scroll !important;
  }

  .chatContainer {
    height: 310px !important;
  }
  #friends {
    height: 360px !important;
  }
  .navBottom2Up {
    -webkit-animation: navmove 2s;
            animation: navmove 2s;
    margin-top: -520px;
    opacity: 1;
  }
  @-webkit-keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -520px;
    }
  }
  @keyframes navmove {
    from {
      opacity: 1;
      margin-top: 0px;
    }

    to {
      opacity: 1;
      margin-top: -520px;
    }
  }

  .navBottom2Down {
    -webkit-animation: navmove1 2s;
            animation: navmove1 2s;
    margin-top: 0px;
    opacity: 0;
  }
  @-webkit-keyframes navmove1 {
    from {
      margin-top: -520px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  @keyframes navmove1 {
    from {
      margin-top: -520px;
      opacity: 1;
    }
    99% {
      opacity: 1;
      margin-top: 1px;
    }
    to {
      opacity: 0;
      margin-top: 0px;
    }
  }
  #chat-messages {
    height: 300px !important;
  }
}

.dayCell {
  height: auto !important;
  padding-top: 0px !important;
}
#dayHeader {
  padding-top: 0px !important;
}

.wrapper {
  /* margin: 1px auto; */
  max-width: 1100px;
}

.container-calendar {
  padding: 14px;
  padding-top: 0px;
  border-radius: 10px;
  min-width: 320px;
  max-width: 520px;

  /* display:none; */
  /* margin: 0 auto; */
  /* overflow: auto; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.calenderLight {
  background: #ffffff;
}
.calenderDark {
  background: #3e62798a;
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #bbbbbb2f;
  color: #fff;
  border: 1px solid #0aa2b5;
  border-radius: 4px;
  padding: 2px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;

  /* border: 1px solid #e2e2e2; */
  text-align: center;
  vertical-align: top;
}
.dropdownMenuEvent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.eventList {
  /* width:250px; */
  background-color: #fff;
}
.eventList:hover {
  /* width:250px; */
  color: #f3f3f3;
  background-color: #14293def;
}
.line-20 {
  line-height: 20px;
}
.date-picker {
  font-family: "Montserrat", sans-serif;
}
.date-picker1 {
  height: 40px;
}
.brake {
  display: block;
  margin-bottom: 7px;
  margin-top: 0px;
  line-height: 0px;
}
.brake1 {
  display: block;
  margin-bottom: 0px;
  margin-top: -11px;
  line-height: 0px;
}
.date-picker:hover {
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff !important;
  background-color: #4e80b65f;
}
.date-picker.selected {
  font-weight: bold;
  /* outline: 1px dashed #00BCD4; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  color: #fff !important;
  background-color: #14293def;
}
.date-picker.selected:hover {
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: #fff !important;
  background-color: #4e80b65f;
}
.calenderLight .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(212, 156, 72) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff0c1b2;
}
.calenderDark .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(107, 93, 73) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #eee56af8;
}
.date-picker.selected span {
  /* border-bottom: 2px solid currentColor; */
}
.removePaddings {
  padding: 0px;
  line-height: 1px;
  height: 1px;
  font-size: 8px;
}

.calenderLight .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #ffe283b2 !important;
  border-radius: 10px;
  background-color: #14293def;
  color: #ffe283b2;
}
.calenderDark .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #eee56af8 !important;
  border-radius: 10px;
  background-color: #fff;
  color: rgb(107, 93, 73);
}
.calenderLight .date-picker.selectedEvent:hover {
  outline: 1px solid #4e80b6 !important;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffe283b2;
  color: rgb(212, 156, 72) !important;
}
.calenderDark .date-picker.selectedEvent:hover {
  cursor: pointer;
  /* outline: 3px solid #71b1f5 !important; */
  border-radius: 10px;
  background-color: #14293def;
  color: rgb(107, 93, 73) !important;
}
/* sunday */
.date-picker:nth-child(1) {
  color: rgb(204, 84, 84);
}

/* friday */
.date-picker:nth-child(6) {
  color: rgb(80, 182, 80);
}

.monthAndYear {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-top: 0;
}
.weeks {
  color: rgb(182, 182, 186);
  font-weight: 400;
}
.badge-throo {
  background-color: #3be8c8;
  color: #fff;
}
.badge-throo2 {
  background-color: #53535373;
  color: #fff;
}
.calenderEventMessage {
  font-family: "Montserrat", sans-serif;
  line-height: 5px !important;
  color: #000 !important;
  letter-spacing: 0.5px;
  padding-top: 1px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  margin: 0px 0 0px !important;
}
.calenderEventMessage:hover {
  color: #fff !important;
}
.button-container-calendar {
  background-color: #4e80b6;
  position: relative;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  margin-bottom: 0.5em;
  overflow: hidden;
  clear: both;
}

.previous {
  float: left;
  font-size: 16px !important;
}

.next {
  float: right;
  font-size: 16px !important;
}

.footer-container-calendar {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar-dark {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #5a748563;
  color: #f3f3f3;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select option {
  color: rgb(70, 67, 67);
}

.nav-link.active.chat {
  background-color: #4e80b6 !important;
  color: #fff !important;
}
.nav-link.chat {
  background-color: transparent !important;
  margin-top: 0px;
  color: #4e80b6 !important;
}
.chatIcon {
  font-size: 18px !important;
  padding-top: 4px;
  padding-left: 4px;
}
.nav-link:hover {
  background-color: #4e80b67e !important;
  color: #fff !important;
}
.chatContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-bottom: 20px; */
  min-width: 320px;
  max-width: 520px;
}

#topmenu span {
  float: left;
  width: 96px;
  height: 70px;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/top-menu.png") -3px -118px
    no-repeat;
}

#topmenu span.friends {
  margin-bottom: -1px;
}
#topmenu span.chats {
  background-position: -95px 25px;
  cursor: pointer;
}
#topmenu span.chats:hover {
  background-position: -95px -46px;
  cursor: pointer;
}
#topmenu span.history {
  background-position: -190px 24px;
  cursor: pointer;
}
#topmenu span.history:hover {
  background-position: -190px -47px;
  cursor: pointer;
}
.friend {
  height: 70px;
  border-bottom: 1px solid #e7ebee;
  position: relative;
}
.friend:hover {
  background: #f1f4f6;
  cursor: pointer;
}
.friend img {
  width: 40px;
  border-radius: 50%;
  margin: 15px;
  float: left;
}
.floatingImg {
  width: 40px;
  border-radius: 50%;
  /* position: absolute; */
  top: 0;
  left: 12px;
  border: 3px solid #fff;
}
.friend p {
  padding: 15px 0 0 0 !important;
  margin: 0px !important;
  float: left !important;
  width: 220px !important;
}
.friend p strong {
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #597a96 !important;
}
.friend p span {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #aab8c2 !important;
}
.friend .status {
  background: #26c281;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 31px;
  right: 17px;
}
.friend .status.away {
  background: #ffce54;
}
.friend .status.inactive {
  background: #eaeef0;
}
.topmenu {
  /* height:520px; */
}
#friends {
  height: 463px;
  overflow-y: scroll;
}

#friends::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
#friends::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
#friends::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
#friends::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
#friends.animate {
  opacity: 1;
  margin-top: 0;
}
#search {
  background: #e3e9ed
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/search.png") -11px 0
    no-repeat;
  height: 60px;
  bottom: -70px;
  position: absolute;
  margin-top: 130px;
  width: 100%;
}
#searchfield {
  background: #e3e9ed;
  margin: 21px 0 0 55px;
  border: none;
  padding: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400px;

  color: #8198ac;
}
#searchfield:focus {
  outline: 0;
}

#chatview {
  min-width: 320px;
  height: 523px;
  /* position:absolute; */
  top: 0;
  left: 0;
  /* display:none; */
  background: #fff;
  border-radius: 0px;
}

#profile {
  height: 130px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  color: #fff;
}
.chatSetting {
  height: 523px;
  overflow-y: scroll !important;
}

.chatSetting::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
.chatSetting::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
.chatSetting::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
.chatSetting::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
.p1 #profile {
  background: #fff
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/timeline1.png") 0 0
    no-repeat;
  background-size: cover;
  height: 130px;
  border-radius: 0px;
}
#profile .avatar {
  width: 68px;
  border: 3px solid #fff;
  margin: 23px 0 0;
  border-radius: 50%;
}
#profile p {
  font-weight: 600;
  font-size: 15px;
  margin: 1px 0 -1px;
  /* opacity:0; */
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#profile p.animate {
  margin-top: 97px;
  opacity: 1;
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#profile span {
  font-weight: 400;
  font-size: 11px;
}
.chatProfile {
  width: 40px;
  border-radius: 50%;
  margin: 15px;
  border: 2px solid #fff;
}
#chat-messages {
  /* opacity:0; */
  /* margin-top: 30px; */
  min-width: 320px;
  height: 335px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-right: 20px; */
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}

#chat-messages::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
#chat-messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
#chat-messages::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
#chat-messages::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
#chat-messages.animate {
  opacity: 1;
  margin-top: 0;
}
#chat-messages label {
  color: #aab8c2;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin: 15px 0;
  min-width: 320px;
  display: block;
}
#chat-messages div.message {
  padding: 0 0 5px 58px;
  clear: both;
  margin-bottom: 45px;
}
#chat-messages div.message.right {
  padding: 0 58px 0px 0;
  margin-right: 0px;
  margin-left: 19px;
}
#chat-messages .message img {
  float: left;
  margin-left: -38px;
  border-radius: 50%;
  width: 30px;
  margin-top: 4px;
}
#chat-messages div.message.right img {
  float: right;
  margin-left: 0;
  margin-right: -38px;
}
.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  /* position:relative; */
  float: left;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
}
.bubble .corner {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/bubble-corner.png")
    0 0 no-repeat;
  /* position:absolute; */

  width: 7px;
  height: 7px;
  left: 0px;
  bottom: 0;
}

div.message.right .corner {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/bubble-cornerR.png")
    0 0 no-repeat;
  left: auto;
  right: -5px;
}
.bubble {
  margin-bottom: 23px;
}
.message span {
  color: #aab8c2;
  font-size: 11px;
  font-weight: 100;
  /* position: absolute; */

  right: 0;
  bottom: -2px;
}
#sendmessage {
  height: 60px;
  border-top: 1px solid #e7ebee;
  /* position:absolute; */
  bottom: 0;
  right: 0px;

  background: #e3e9ed;
  padding-bottom: 50px;
}
#sendmessage i {
  font-size: 16px !important;
}
#sendmessage input {
  background: #fff;
  margin: 21px 0 0 21px;
  border: none;
  padding: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400px;
  font-weight: 320px;
  width: 240px;

  background: #e3e9ed;
  color: #787b7c;
}
#sendmessage input:focus {
  outline: 0;
}
#sendmessage button {
  width: 30px;
  height: 30px;
  /* position:absolute; */
  /* right: 15px; */
  /* top: 23px; */
  border: none;
}
#sendmessage button:hover {
  cursor: pointer;
  background-position: 0 0;
}
#sendmessage button:focus {
  outline: 0;
}

#chatview,
#sendmessage {
  overflow: hidden;
  border-radius: 6px;
}
#chatview {
  border-radius: 0px !important;
}
#send {
  background: transparent !important;
  margin-right: 23px;
  margin-top: 15px;
  /* align-items: right;
  display:absolute; */
  /* right:0; */
  color: #4e80b6;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[class="active"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #3cff00;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[class="away"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[class="inactive"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #5f5e5e;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
@media only screen and (min-width: 1930px) and (max-width: 2250px) {
}

#noticeBoard {
  padding: 0;
  margin: 0;
  margin-right: 1%;
}

#noticeBoard {
  margin-top: 2px;
  margin-right: 20px;
  height: 490px;
  border-radius: 10px;
  position: relative;
  background-color: #273e57;
  /* max-width: 1900px; */
  font-family: "Architects Daughter", cursive !important;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}
.noticeOpen1 {
  -webkit-animation: noticeOpen 2s;
          animation: noticeOpen 2s;
  height: 490px;
  /* opacity: 1; */
}
@-webkit-keyframes noticeOpen {
  from {
    height: 70px;
  }

  to {
    height: 490px;
  }
}
@keyframes noticeOpen {
  from {
    height: 70px;
  }

  to {
    height: 490px;
  }
}
.noticeClose {
  -webkit-animation: noticeClose 2s;
          animation: noticeClose 2s;
  height: 70px;
  /* opacity: 1; */
}
@-webkit-keyframes noticeClose {
  from {
    height: 490px;
  }

  to {
    height: 70px;
  }
}
@keyframes noticeClose {
  from {
    height: 490px;
  }

  to {
    height: 70px;
  }
}
#add_note {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: #4e80b6;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  font-family: "Montserrat", sans-serif;
  margin-top: 430px;
  position: absolute;
  outline: none;
  cursor: pointer;
  right: 10px;
}

#add_note:hover {
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6;
}

#modal {
  display: none;
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 490px;
  background-color: rgba(72, 124, 140, 0.507);
  border-radius: 10px;
}

#inner_modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 490px;
}

#inner_modal textarea {
  width: 276px;
  height: 276px;
  padding: 25px;
  outline: none;
  resize: none;
  font-size: 1.5rem;
  font-family: "Architects Daughter", cursive !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.9);
}

#inner_modal i {
  font-size: 1.4rem;
  color: rgb(19, 54, 87) !important;
  cursor: pointer;
  margin-top: -245px;
  margin-left: -55px;
  padding-right: 65px;
  transition: 1s ease-in-out;
  z-index: 10;
}
.noteClose {
  position: absolute;
  right: 0;
  color: rgba(44, 48, 56, 0.59);
}
#inner_modal i:hover {
  color: lightgray;
}

.containerNotice {
  /* width: 1280px; */
  /* margin: auto; */
  padding: 0;
  margin: 0;
}

#header {
  color: white;
  padding: 0 20px;
  min-height: 70px;
  text-shadow: 1px 1px black;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  font-family: "Architects Daughter", cursive !important;
}
#headerNotice {
  color: white !important;
  padding: 0 20px !important;
  min-height: 70px !important;
  text-shadow: 1px 1px black !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  align-items: center !important;
  font-family: "Architects Daughter", cursive !important;
}
.fas {
  color: #c2ff3d;
}

#all_notes {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 15px;
  position: absolute;
  /* z-index: 9; */
  height: 420px;
  overflow-y: scroll;
  width: 100%;
}
#all_notes::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
#all_notes::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 0px;
}
#all_notes::-webkit-scrollbar-thumb {
  background: #71a3d1;
  border-radius: 0px;
}
#all_notes::-webkit-scrollbar-thumb:hover {
  background: #bdbebf;
}
.note {
  width: 160px;
  height: 160px;
  transition: 2s;
  cursor: pointer;
  box-shadow: 0px 10px 24px 0px rgba(0, 0, 0, 0.75);
  overflow-y: scroll;
  word-wrap: break-word;
}
.note::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  background: transparent;
}
.note::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(255, 255, 255, 0);
  border-radius: 0px;
}
.note::-webkit-scrollbar-thumb {
  background: #000000;
  border-radius: 0px;
}
.note::-webkit-scrollbar-thumb:hover {
  background: transparent;
}
.note h1 {
  font-size: 1.5rem;
  word-wrap: break-word;
}

.details {
  margin-top: 25px;
  padding: 0 15px;
  font-size: 1.5rem;
}

.details i {
  color: whitesmoke;
  cursor: pointer;
  text-shadow: 1px 1px #bbb;
}

@media (max-width: 1280px) {
  .containerNotice {
    width: 100%;
  }
}

@media (max-width: 200px) {
  #header {
    padding: 20px;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
@media only screen and (min-width: 200px) and (max-width: 470px) {
  .headerNotice {
    display: none;
  }
  .bodyContain {
    padding-right: 5px !important;
    padding-left: 5px !important;
    margin: 0px !important;
  }

  .note {
    width: 130px;
    height: 130px;
  }
}

.outer {
  position: relative;
  margin: auto;
  width: 35px;
  margin-top: 7px;
  margin-right: 0px;
  cursor: pointer;
}

.inner {
  width: inherit;
  text-align: center;
}

.noticeOpen {
  font-size: 0.8em;
  line-height: 1.4em;
  margin-top: 7px;
  text-transform: uppercase;
  color: #fff;
  transition: all 0.3s ease-in;
  opacity: 0;
  cursor: pointer;
}

.inner:before,
.inner:after {
  position: absolute;
  content: "";
  height: 0.5px;
  width: inherit;
  background: #42b5d8;
  left: 0;
  transition: all 0.3s ease-in;
}

.inner:before {
  top: 50%;
  transform: rotate(45deg);
}

.inner:after {
  bottom: 50%;
  transform: rotate(-45deg);
}

.outer .cl label {
  opacity: 1;
}

.outer.cl .inner:before,
.outer.cl .inner:after {
  margin-top: 5px;
  transform: rotate(0);
}

.outer.cl .inner:before {
  top: 0;
}

.outer.cl .inner:after {
  bottom: 0;
}

.questionPart {

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   
}
.badge-transparent{
  background-color: rgba(36, 35, 35, 0.384);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
/* The container */
.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark {
  position: absolute;
  top: 15px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel {
  padding-left: 40px;
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark {
  padding-left: 40px;
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .radioMark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .radioMark {
  background-color: rgb(71, 233, 106);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .radioMark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .radioMark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* The container */
.containerRadio2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark2 {
  position: absolute;
  top: 7px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel2 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark2 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio2:hover input ~ .radioMark2 {
  background-color: rgba(243, 243, 243, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio2 input:checked ~ .radioMark2 {
  background-color: rgba(0, 0, 0, 0.323);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio2 input:checked ~ .radioMark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio2 .radioMark2:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
@media only screen and (min-width: 300px) and (max-width: 750px) {
  .radioMarkLabel {
    display: none;
  }
  .radioMarkLabelDark {
    display: none;
  }
  .checkMarkLabel {
    display: none;
  }
  .checkMarkLabelDark {
    display: none;
  }
}

select {
  font-family: "FontAwesome", "sans-serif";
}
textarea.answer {
  padding: 10px;
  width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}
.answerPre {
  /* padding: 10px; */
  margin-top: 5px;
  /* line-height: 0.7; */
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* The container */
.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMark {
  position: absolute;
  top: 15px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(110, 108, 108, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.checkMarkLabel {
  padding-left: 40px;
  color: rgb(28, 65, 36);
}

.checkMarkLabelDark {
  padding-left: 40px;
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerCheck:hover input ~ .checkMark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkMark {
  background-color: rgb(71, 233, 106);
}

/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMark when checked */
.containerCheck input:checked ~ .checkMark:after {
  display: block;
}

/* Style the checkMark/indicator */
.containerCheck .checkMark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

/* The container */
.containerCheckPre {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckPre input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMarkPre {
  position: absolute;
  top: 12px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(197, 197, 197, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* On mouse-over, add a grey background color */
.containerCheckPre:hover input ~ .checkMarkPre {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckPre input:checked ~ .checkMarkPre {
  background-color: rgb(71, 233, 106);
}

/* Create the checkMarkPre/indicator (hidden when not checked) */
.checkMarkPre:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMarkPre when checked */
.containerCheckPre input:checked ~ .checkMarkPre:after {
  display: block;
}

/* Style the checkMarkPre/indicator */
.containerCheckPre .checkMarkPre:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}



/* The container */
.containerCheckPre2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckPre2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMarkPre {
  position: absolute;
  top: 12px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(197, 197, 197, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* On mouse-over, add a grey background color */
.containerCheckPre2:hover input ~ .checkMarkPre {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckPre2 input:checked ~ .checkMarkPre {
  background-color:rgb(187, 54, 2);;
}

/* Create the checkMarkPre/indicator (hidden when not checked) */
.checkMarkPre:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMarkPre when checked */
.containerCheckPre2 input:checked ~ .checkMarkPre:after {
  display: block;
}

/* Style the checkMarkPre/indicator */
.containerCheckPre2 .checkMarkPre:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkMarkPreLabel {
  color: rgb(28, 65, 36);
}

.checkMarkLabelPreDark {
  color: rgba(238, 236, 236, 0.685);
}
.previewQuestion {
  background-color: rgba(255, 255, 255, 0.534);
  min-height: 100px;
  padding-bottom: 30px;
}
.previewQuestion2 {
  background-color: rgba(202, 205, 206, 0.205);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(100, 100, 111, 0.034) 0px 7px 29px 0px;
}
.previewQuestion2Correct {
  background-color: rgba(46, 216, 111, 0.466);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestion2Wrong {
  background-color: rgba(255, 82, 51, 0.562);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestionDark2Wrong {
  background-color: rgba(240, 101, 76, 0.562);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestionDark2 {
  border-radius: 10px;
  background-color: rgba(9, 9, 31, 0.377);
  min-height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
}
.previewQuestionDark2Correct {
  border-radius: 10px;
  background-color: rgba(46, 216, 111, 0.466);
  min-height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
}
.previewQuestionDark {
  background-color: rgba(65, 63, 63, 0.548);
  min-height: 100px;
  padding-bottom: 30px;
}
.answerAlignment {
  min-width: 300px;
  /* max-width:500px; */
}
.darkHR {
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  border: none;
}
.lightHR {
}

/* The container */
.containerRadio3 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -7px;
}

/* Hide the browser's default radio button */
.containerRadio3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark3 {
  position: absolute;
  top: 9px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid rgba(156, 155, 155, 0.466);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel3 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark3 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio3:hover input ~ .radioMark3 {
  background-color: rgba(218, 212, 212, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio3 input:checked ~ .radioMark3 {
  background-color: rgb(71, 233, 106);
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio3 input:checked ~ .radioMark3:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio3 .radioMark3:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


.matchingMobile{
  display: none;
}
.matchingWeb{
  display: block;
}

/* The container */
.containerRadio4 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -7px;
}

/* Hide the browser's default radio button */
.containerRadio4 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark3 {
  position: absolute;
  top: 9px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid rgba(156, 155, 155, 0.466);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel3 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark3 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio4:hover input ~ .radioMark3 {
  background-color: rgba(218, 212, 212, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio4 input:checked ~ .radioMark3 {
  background-color: rgb(187, 54, 2);
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio4 input:checked ~ .radioMark3:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio4 .radioMark3:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
@media only screen and (min-width: 550px) and (max-width: 700px) {
.matchingConnecter{
  display: none;
}
.matchingConnecterHR{
  display: none;
}
}
@media only screen and (min-width: 380px) and (max-width: 550px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }
}

@media only screen and (min-width: 300px) and (max-width: 380px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }

.matchingInput{
  width:230px;
}
}

@media only screen and (min-width: 0px) and (max-width: 360px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }

.matchingInput{
  width:190px;
}
}
.fillBoard {

  height: 40px;
  min-width: 170px;
  max-width: 300px;
  border: 1px solid #0000004b;
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.fillBarDrag {
  border: 1px solid rgb(133, 126, 126);
  color:rgb(119, 113, 113);
}
.staticFaIcon{
  font-size: 15px;
}
.fillBar {
  height: 30px;
  cursor:pointer;
  box-shadow: rgba(39, 39, 58, 0.116) 0px 4px 16px,
    rgba(17, 17, 26, 0.24) 0px 8px 32px;
  color: rgb(255, 255, 255);
  background-color: rgba(117, 119, 151, 0.548);
  border-radius: 5px;
}
  .previewQuestionDark2 .fillBar{
    box-shadow: rgba(255, 255, 255, 0.1) 0px 4px 16px,
    rgba(255, 255, 255, 0.05) 0px 8px 32px;
}
.fillClose {
  border: none;
  color: #f8f7f7a8;
  background-color: transparent;
  cursor: pointer;
}

.fillClose:hover {
  border: none;
  color: #8f8c8c7e;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
}
#loader {
  /* Uncomment this to make it run! */
  /*
     animation: loader 5s linear infinite; 
  */
 
  position: fixed;
  top: calc(50% - 20px);
  left: calc(50% - 20px);
  z-index:9000;
}
@-webkit-keyframes loader {
  0% { left: -100px }
  100% { left: 110%; }
}
@keyframes loader {
  0% { left: -100px }
  100% { left: 110%; }
}
#box {
  width: 50px;
  height: 50px;
  /* background: #6997DB; */
  -webkit-animation: animate .5s linear infinite;
          animation: animate .5s linear infinite;
  position: absolute;
  top: 0;
  
  left: 0;
  border-radius: 3px;
}
@-webkit-keyframes animate {
  17% { border-bottom-right-radius: 3px; }
  25% { transform: translateY(9px) rotate(22.5deg); }
  50% {
    transform: translateY(18px) scale(1,.9) rotate(45deg) ;
    border-bottom-right-radius: 40px;
  }
  75% { transform: translateY(9px) rotate(67.5deg); }
  100% { transform: translateY(0) rotate(90deg); }
}
@keyframes animate {
  17% { border-bottom-right-radius: 3px; }
  25% { transform: translateY(9px) rotate(22.5deg); }
  50% {
    transform: translateY(18px) scale(1,.9) rotate(45deg) ;
    border-bottom-right-radius: 40px;
  }
  75% { transform: translateY(9px) rotate(67.5deg); }
  100% { transform: translateY(0) rotate(90deg); }
} 
#shadow { 
  width: 50px;
  height: 5px;
  background: #000;
  opacity: 0.1;
  position: absolute;
  top: 59px;
  left: 0;
  border-radius: 50%;
  -webkit-animation: shadow .5s linear infinite;
          animation: shadow .5s linear infinite;
}
@-webkit-keyframes shadow {
  50% {
    transform: scale(1.2,1);
  }
}
@keyframes shadow {
  50% {
    transform: scale(1.2,1);
  }
}



.contentStyle {
  margin: 0%;
  padding-top: 1%;
  width: 100%;
}
.contentStyleDark {
  margin: 0%;
  padding-top: 1%;
  color: #fff;
  width: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.buttonNext {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border: none;
  background-color: #4e80b67e ;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonNext:hover:before {
  content: "Next ";
}

.buttonPrevious {
  border-top-left-radius: 70px;
  border-bottom-left-radius: 70px;
  border: none;
  background-color: #4e80b67e ;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonPrevious:hover:after {
  content: " Previous";
}

/* content styles */
.cursive {
  font-family: "Mr Dafoe";
  padding-top: 0;
  margin-top: -20px;
  font-size: 24px;
  color: white;
  text-shadow: 0 0 0.05em #fff, 0 0 0.2em #fe05e1, 0 0 0.3em #fe05e1;
  transform: rotate(-1deg);
}
.textShadow1 {
  text-shadow: 2px 2px 3px rgb(89, 225, 26);
}
.textShadow2 {
  text-shadow: 2px 2px 3px rgb(40, 140, 247);
}
.textShadow3 {
  text-shadow: 2px 2px 3px rgb(214, 19, 19);
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
  .userContentHead {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody h1 span {
    font-size: 1.7rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody p span {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody {
    padding: 10px;
  }
}
@media only screen and (max-width: 600px) and (min-width: 450px) {
  .userContentHead {
    font-size: 2rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody p span {
    font-size: 1.7rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody h1 span {
    font-size: 1.5rem !important;
    padding-bottom: 20px;
    padding-top: 10px;
  }
  .userContentBody {
    padding: 20px;
  }
}

.backImageContent {
     height: 650px; 
}
.NotFound{
background: url(/static/media/80911.65fc5b1a.jpg);
background-position: bottom;
background-size: cover;
height: 650px;
order: -1;
z-index: -10;
}
.notFoundAlign{
     padding-top: 15%;
     padding-left:10%;
     max-width: 800px;
     min-width: 280px;
}
@media only screen and (min-width: 2300px) and (max-width: 5000px) {
     .notFoundAlign{
          padding-top: 10%;
          max-width: 1500px;
          min-width: 480px;
     }
     .headAlign{
          font-size: 60px;
     }
     .contentAlign{
          font-size: 30px;
          line-height: 40px;
          
     }
}
@media only screen and (min-width: 1800px) and (max-width: 2300px) {
     .notFoundAlign{
          padding-top: 10%;
          max-width: 1200px;
          min-width: 480px;
     }
     .headAlign{
          font-size: 50px;
     }
     .contentAlign{
          font-size: 25px;
          line-height: 35px;
          
     }
}
@media only screen and (min-width: 1000px) and (max-width: 1800px) {
     .headAlign{
          font-size: 40px;
     }
     .contentAlign{
          font-size: 20px;
          line-height: 30px;
     }
}
@media only screen and (min-width: 800px) and (max-width: 1000px) {
     .headAlign{
          font-size: 34px;
     }
     .contentAlign{
          font-size:17px;
          line-height: 30px;
     }
     .backImageContent{
          height: 500px;
     }
}
@media only screen and (min-width: 500px) and (max-width: 800px) {
     .headAlign{
          font-size: 30px;
     }
     .contentAlign{
          font-size:15px;
          line-height: 25px;
     }
     .backImageContent{
          height: 450px;
     }
}
@media only screen and (min-width: 300px) and (max-width: 500px) {
     .headAlign{
          font-size: 20px;
     }
     .contentAlign{
          font-size:10px;
          line-height: 20px;
     }
     .backImageContent{
          height: 400px;
     }
     .notFoundAlign{
          padding-top: 20%;
          padding-left:10%;
          max-width: 800px;
          min-width: 200px;
     }
}
@media only screen and (min-width: 200px) and (max-width: 300px) {
     .headAlign{
          font-size: 20px;
     }
     .contentAlign{
          font-size:10px;
          line-height: 20px;
     }
     .backImageContent{
          height: 400px;
     }
     .notFoundAlign{
          padding-top: 20%;
          padding-left:10%;
          max-width: 800px;
          min-width: 200px;
     }
}
.bodyContent {
  /* height: 65rem; */
  /* pointer-events:none; */
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.93),
      rgba(255, 255, 255, 0.93)
    ),
    url(/static/media/imageBack2.79099320.jpg);
  color: black;

}
.layerBody{
  /* pointer-events:none;  */
  display:none;
  background-color: #08111f9c;
  height:10000px;
  z-index: 10000;
  width:100%;
  position: fixed;
}
.createContentStyle {
  padding: 4%;
  margin: 0%;
  padding-top: 3%;
}

.bodyContentDark {
  /* height: 155rem; */
  background-color: #212534;
  background-image: linear-gradient(
      to bottom,
      rgba(33, 37, 52, 0.946),
      rgba(33, 37, 52, 0.953)
    ),
    url(/static/media/imageBack2.79099320.jpg);
  /* color: white; */
}
.leftHead {
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #003466;
  padding: 10px;
  padding-left: 25px;
}
.leftHead:hover {
  background-color: #cdd3da;
}
.leftHeadDark {
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #87c0f5;
  padding: 10px;
  padding-left: 25px;
}
.leftHeadDark:hover {
  color: #003466;
  background-color: #cdd3da;
}
.leftHeadTop {
  font-size: 18px;
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #003466;
}
.leftHeadTopDark {
  font-size: 18px;
  line-height: 1px;
  font-family: "Montserrat", sans-serif;
  color: #87c0f5;
}
.leftHeadIcon {
}

.RentalUnitsMapOn:hover .menuIndex {
  background-color: #4e80b67a;
}
.RentalUnitsMapOff .menuIndex {
  background-color: #4e80b67a !important;
}

.containerMenu {
  display: inline-block;
  cursor: pointer;
  padding-right: 21px;
  padding-left: 12px;
  padding-top: 7px;
}
.menuIndex {
  position: relative;
  text-align: right;
  float: right;
  margin-right: -52px;
  margin-top: -8px;
  z-index: 999;
  background-color: #4e80b63b;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.bar1,
.bar2,
.bar3 {
  width: 20px;
  height: 3.3px;
  background-color: #fff;
  margin: 4.5px 0;
  transition: 0.4s;
}

.change .bar1 {
  transform: rotate(-45deg) translate(-4px, 6px);
}

.change .bar2 {
  opacity: 0;
}

.change .bar3 {
  transform: rotate(45deg) translate(-4px, -7px);
}

.RentalUnitsMapOff {
  -webkit-animation: slideRight 1s linear 1;
          animation: slideRight 1s linear 1;
  width: 280px;

  transform: translateX(-280px);
  z-index: 999;
  height: 100%;

  position: fixed;
}
.top130 {
  top: 48px;
}
.RentalUnitsMapOn {
  -webkit-animation: slideRight1 1s linear 1;
          animation: slideRight1 1s linear 1;
  width: 280px;
  z-index: 999;
  height: 100%;
  position: fixed;
}

@-webkit-keyframes slideRight {
  0% {
    transform: translateX(0px);
  }
  55% {
  }
  100% {
    transform: translateX(-280px);
  }
}

@keyframes slideRight {
  0% {
    transform: translateX(0px);
  }
  55% {
  }
  100% {
    transform: translateX(-280px);
  }
}
@-webkit-keyframes slideRight1 {
  0% {
    transform: translateX(-280px);
  }
  99% {
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes slideRight1 {
  0% {
    transform: translateX(-280px);
  }
  99% {
  }
  100% {
    transform: translateX(0px);
  }
}

@media only screen and (min-width: 220px) and (max-width: 800px) {
  .RentalUnitsMapOff {
    -webkit-animation: slideRight 1s linear 1;
            animation: slideRight 1s linear 1;
    width: 250px;
    transform: translateX(-250px);
    position: absolute;
    z-index: 999;
  }
  .RentalUnitsMapOn {
    transform: translateX(0px);
    -webkit-animation: slideRight1 1s linear 1;
            animation: slideRight1 1s linear 1;
    width: 250px;
    position: absolute;
  }
  @-webkit-keyframes slideRight {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-250px);
    }
  }
  @keyframes slideRight {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(-250px);
    }
  }
  @-webkit-keyframes slideRight1 {
    0% {
      transform: translateX(-250px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  @keyframes slideRight1 {
    0% {
      transform: translateX(-250px);
    }
    100% {
      transform: translateX(0px);
    }
  }
}

.StepRound {
  background-color: blueviolet;
}
.social-container {
  margin: 20px 0;
}
.titleStepOpp {
  display: none;
}
.social-container-step {
  border: 1px solid #dddddd;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0 0px;
  height: 40px;
  width: 40px;
}
.activeStep {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff !important;
}
.activeLine {
  border-bottom: 2px solid #ff4b2b !important;
}
.SideLine {
  /* width: 112px; */
  height: 47px;
  margin-top: -25px;
  border-bottom: 2px solid #e6dddab9;
  /* margin-left: -5%;
    margin-right: -5%; */
}
.stepFormNavTitle {
  padding-top: 25px;
}
.stepFormNav {
  /* padding:10%; */
  padding-top: 100px;
}
.stepSpan {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}
.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  align-self: center;
}
.ApplicationInputAgg {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  /* min-width: 240px;
  max-width: 340px; */

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInput1 {
  min-width: 240px;
  max-width: 340px;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;
  width: 100%;
  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;

  margin-bottom: 0px;
}
.ApplicationInput2 {
  min-width: 240px;
  max-width: 340px;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  font-size: 12px;

  border-bottom: 1px solid #30302f;
  padding-bottom: 0px;

  margin-bottom: 0px;
}
.alignInput {
  min-width: 250px;
  max-width: 350px;
}
.w-5 {
  width: 5%;
}
.w-10 {
  width: 10%;
}
.w-15 {
  width: 15%;
}
.w-20 {
  width: 20%;
}
.w-25 {
  width: 25%;
}
.ApplicationInputSelect {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
  /* padding-right:50px; */
  min-width: 240px;
  max-width: 340px;
}
.searchListHeadApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
}
.searchListHeadAppDark {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: normal;
  word-wrap: break-word !important;
  /* white-space: nowrap !important; */
  display: inline-block;
  color: #fff;
}
.inputContainer {
  padding: 50px;
}
.outSpan {
  /* display: inline-block; */
  /* white-space: nowrap !important; */
}
.noBreck {
  /* white-space: nowrap; */
  word-break: break-word !important;
}
.searchListContentApp {
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  color: #615a5a;
}
.informationListApp {
  background-color: #dddddd;
  padding-top: 15px;
  border: 1px solid #cac4c4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppAlign {
  padding: 50px;
}
.informationListAppNormal {
  background-color: transparent;
  padding-top: 15px;
  border: 1px solid #e4e4e4;
  padding-bottom: 15px;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 1px 16px -6px rgb(189, 183, 183);
}
.informationListAppInfo {
  background-color: #ddeff3;
  padding-top: 15px;
  border: 1px solid #ddeff3;
  padding-bottom: 15px;
  color: #29bddf;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.informationListAppWarning {
  background-color: #f1f1c9;
  padding-top: 15px;
  border: 1px solid #f1f1c9;
  padding-bottom: 15px;
  color: #999731;
  padding-right: 25px;
  padding-left: 25px;
  text-align: justify;
  box-shadow: 0 8px 16px -6px rgb(189, 183, 183);
}
.appFootButton {
  margin-top: 20px;
  padding-right: 50px;
  padding-bottom: 50px;
}
hr.line {
  border-bottom: 1px solid rgba(224, 220, 220, 0.603);
  border-top: transparent;
}
.spanValidateApp {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  align-self: flex-end;
  /* padding-right: 10px; */
  /* position:fixed; */
  margin-top: -10px;
  margin-bottom: -3px;
}

.licenseButton {
  background-color: transparent;
  border: 1px solid #ff4b2b;
  color: #ff4b2b;
}
.licenseButton:hover {
  background-color: #ff4b2b;
  border: 1px solid #ff4b2b;
  color: #fff;
}
.dragDrop {
  height: 250px;
  border: 9px dashed rgb(192, 174, 170);
  border-radius: 5px;
}
.licenseHead {
  font-family: "Montserrat", sans-serif;
}
.licenseHeadDark {
  font-family: "Montserrat", sans-serif;
  color: #fff;
}
.absolute {
  position: absolute;
}
.licenseImage {
  height: 250px;
  opacity: 0.5;
}

.pdf {
}

.pdf::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.pdf::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.pdf::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.pdf::-webkit-scrollbar-thumb:hover {
  background: #918c8b;
}
.applicationContainer {
  margin: 4%;
  /* margin-top:120px; */
  /* margin-top:100px; */
}
.applicationCard {
  background-color: #fff;
  border-radius: 15px;
  margin-bottom: 15px;
}
.applicationCardDark {
  background-color: rgb(151, 150, 150);
  border-radius: 15px;
  margin-bottom: 15px;
}
.appImage {
  height: 50px;
  width: 50px;
}
.appImage1 {
  height: 50px;
}
.addApp {
  margin-top: -10px;
}
.applicationText {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationTextDark {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #fafafa;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.applicationText1 {
  font-size: 15px;
  font-family: "Montserrat", sans-serif;
  color: #807e7e;
  letter-spacing: 0.5px;
  line-height: 25px;
  font-size: 15px;
}
.appStatus {
  text-align: "center";
  padding: 7px;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  margin-bottom: 10px;
}

/* #Toggle
   */

.switchApplication {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 27px;
}
.switchApplication1 {
  position: relative;
  display: inline-block;
  width: 170px;
  height: 27px;
}
.switchApplication input {
  display: none;
}
.switchApplication1 input {
  display: none;
}

.sliderApplication {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  transition: 0.4s;
}
.sliderApplication1 {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #b7bbba;
  transition: 0.4s;
}
.sliderApplication:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
.sliderApplication1:before {
  position: absolute;
  content: "";
  height: 21px;
  width: 22px;
  left: 2px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .sliderApplication {
  background-color: #ff4b2bc7;
}
input:checked + .sliderApplication1 {
  background-color: #ff4b2bc7;
}
input:focus + .sliderApplication {
  box-shadow: 0 0 1px #2196f3;
}
input:focus + .sliderApplication1 {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .sliderApplication:before {
  transform: translateX(54px);
}
input:checked + .sliderApplication1:before {
  transform: translateX(144px);
}
/*------ ADDED CSS ---------*/
.onApp {
  display: none;
}

.onApp,
.offApp {
  color: rgb(235, 229, 229);
  position: absolute;
  transform: translate(-50%, -50%);
  top: 47%;
  left: 50%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .sliderApplication .onApp {
  display: block;
}
input:checked + .sliderApplication1 .onApp {
  display: block;
}
input:checked + .sliderApplication .offApp {
  display: none;
}
input:checked + .sliderApplication1 .offApp {
  display: none;
}
/*--------- END --------*/

/* Rounded sliders */
.sliderApplication.round {
  border-radius: 34px;
}
.sliderApplication1.round {
  border-radius: 34px;
}
.sliderApplication.round:before {
  border-radius: 50%;
}
.sliderApplication1.round:before {
  border-radius: 50%;
}
.ticTerm1 {
  display: none;
}
.stepTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 3px;

  white-space: nowrap;
  position: absolute;

  /* align-items: center; */
  align-self: center;
}

.infoIcon {
  margin-left: 15px;
  /* margin-top:5px; */
  font-size: 20px;
  cursor: help;
  color: #ff4b2b;
}
.infoIcon:hover {
  color: #968c8c;
}
.infoContent {
  display: none;
  position: absolute;
  z-index: 1000;
}
.infoHandel:hover .infoContent {
  display: block;
}
.iconL {
  color: #999393;
}

.drop-list {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  height: 25px;
  padding-left: 5px;
  color: #4f5050;
}
.drop-list:hover {
  background-color: #e8e8e9;
  cursor: pointer;
  color: #000;
}
.act {
  background-color: #cccdce;
}

.searchList {
  max-height: 150px;

  width: 93%;
  z-index: 100;
  overflow-y: scroll;
  position: absolute;
  /* margin-right: 8px; */
}
.searchList::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
.searchList::-webkit-scrollbar-track {
  background: #dad9d9;
}

/* Handle */
.searchList::-webkit-scrollbar-thumb {
  background: #ff4b2b;
}

/* Handle on hover */
.searchList::-webkit-scrollbar-thumb:hover {
  background: #aca9a8;
}

.checkedContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default checkbox */
.checkedContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
}

/* On mouse-over, add a grey background color */
.checkedContainer:hover input ~ .checkmark {
  background-color: #ff9380;
}

/* When the checkbox is checked, add a blue background */
.checkedContainer input:checked ~ .checkmark {
  background-color: #ff4b2b;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkedContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkedContainer .checkmark:after {
  left: 10px;
  top: 7px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.optionContainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 12px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 2px;
  white-space: pre;
}

/* Hide the browser's default radio button */
.optionContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark1 {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #ccc;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.optionContainer:hover input ~ .checkmark1 {
  background-color: #ff9380;
}

/* When the radio button is checked, add a blue background */
.optionContainer input:checked ~ .checkmark1 {
  background-color: #ff4b2b;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark1:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.optionContainer input:checked ~ .checkmark1:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.optionContainer .checkmark1:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.optionTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  white-space: nowrap;

  /* align-items: center; */
  align-self: center;
}
.SideLine {
  display: block;
}
.SideLineHide {
  display: block;
}
.applicationAgreement {
  padding-right: 100px;
  padding-left: 100px;
  padding-top: 40px;
}
.applicationAgreementMobile {
  min-width: 1100px;
  max-width: 1100px;
  padding-right: 30px;
  padding-left: 30px;
  padding: 0px;
  margin: 0px;
}
.applicationAgreementHead {
  padding-top: 50px;
  padding-left: 80px;
}
.applicationAgreementHead4 {
  padding-top: 30px;
  padding-left: 60px;
  padding-right: 60px;
}
.applicationAgreementHeadBar {
  background-color: rgba(224, 220, 220, 0.603);
  border: 1px solid rgb(228, 226, 226);
  height: 20px;
  margin: 30px;
  margin-bottom: 0px;
}
.applicationAgreementHeadLeftBox {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeft {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
}
.applicationAgreementHeadLeftBoxMobile {
  border: 1px solid #000;
  padding: 0px;
  margin: 0px;
  margin-top: 27px;
  display: none;
}
.agreementLogo {
  width: 190px;
  margin-bottom: 20px;
}
.applicationAgreementHeadRight {
  padding-right: 55px;
  padding-left: 25px;
  margin: 0px;
}
.applicationAgreementHead2 {
  padding-top: 30px;
  padding-left: 80px;
  padding-left: 80px;
}
.applicationAgreementHead3 {
  padding-top: 0px;
  padding-right: 80px;
  padding-left: 80px;
}
.agreementTableBody {
  border: 1px solid #000;
  margin-right: 30px;
  margin-left: 30px;
}
.agreementTableBody1 {
  margin-right: 40px;
  margin-left: 40px;
}
.borderBlack {
  /* border:1px solid #000; */
  border-style: ridge;
}
.borderBlack1 {
  /* border:1px solid #000; */
  border: ridge 1.7px #fff;
}
.borderRightBlack {
  border-right: 1px solid #000;
}
.borderLeftBlack {
  border-left: 1px solid #000;
}
.borderTopBlack {
  border-top: 1px solid #000;
}
.borderBottomBlack {
  border-bottom: 1px solid #000;
}
.ApplicationInputAgreement {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  /* width: 100%; */

  /* padding-right:50px; */
  font-size: 12px;
}
.ApplicationInputAgreement2 {
  background-color: rgba(224, 220, 220, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
  height: 70%;
  /* padding-right:50px; */
  font-size: 12px;
}
.bgGray {
  background-color: rgba(224, 220, 220, 0.603);
}
.ApplicationInputAgreement3 {
  background-color: rgba(255, 255, 255, 0.603);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;

  width: 100%;
  height: 100%;
  /* padding-right:50px; */
  font-size: 12px;
}
.agreementTitleBar {
  background-color: #000;
  color: #fff;
}
.threeQuarterBreak {
  width: 75%;
}

.headApplicationReview {
  font-size: 19px;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.7px;
}
.headApplicationReview1 {
  font-weight: 600;
  letter-spacing: 2px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
}
.fixedInputSize {
  min-width: 150px;
}
.tableLeftPad {
  padding-left: 15px;
}
.quarterBreak {
  min-width: 130px;
}
.tableResNo {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.tableResNo2 {
  display: none;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
}
.pageNumberAlign {
  text-align: right;
}
.preEmployeeHide1 {
  display: block;
}
.preEmployeeHide2 {
  display: none;
}
@media only screen and (min-width: 1900px) and (max-width: 5900px) {
  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .inputContainer {
    padding: 80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .applicationAgreement {
    padding-right: 400px;
    padding-left: 400px;
    padding-top: 60px;
  }
  .managerLandlord {
    min-width: 400px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 60px;
  }
}
@media only screen and (min-width: 1450px) and (max-width: 1900px) {
  .alignInput {
    min-width: 280px;
    max-width: 380px;
  }
  .inputContainer {
    padding: 80px;
  }
  .subTitle {
    padding-left: 80px;
  }
  .stepTitle {
    font-size: 18px;
  }
  .applicationAgreement {
    padding-right: 200px;
    padding-left: 200px;
    padding-top: 60px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 50px;
  }
}
@media only screen and (min-width: 1270px) and (max-width: 1450px) {
  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 24px;
  }
  .inputContainer {
    padding: 60px;
    padding-bottom: 0px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .managerLandlord {
    min-width: 350px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
}
@media only screen and (min-width: 1100px) and (max-width: 1270px) {
  .headApplicationReview {
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 23px;
  }
  .inputContainer {
    padding: 60px;
  }
  .subTitle {
    padding-left: 60px;
  }
  .stepTitle {
    font-size: 16px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .managerLandlord {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}

@media only screen and (min-width: 850px) and (max-width: 1100px) {
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .tableLeftPad {
    padding-left: 13px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .inputContainer {
    padding: 40px;
  }
  .subTitle {
    padding-left: 40px;
  }
  .stepTitle {
    font-size: 15px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 60px;
  }
  .managerLandlord {
    min-width: 420px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadRight {
    /* padding:0px; */
    min-width: 400px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 770px) and (max-width: 850px) {
  .tableLeftPad {
    padding-left: 13px;
  }
  .headApplicationReview {
    font-size: 15px;
    line-height: 17px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 21px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }

  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .inputContainer {
    padding: 30px;
  }
  .subTitle {
    padding-left: 30px;
  }
  .stepTitle {
    font-size: 15px;
  }

  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 50px;
    padding-left: 50px;
    padding-top: 60px;
  }
  .managerLandlord {
    min-width: 320px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .halfBreak {
    min-width: 500px;
  }
  .threeQuarterBreak {
    min-width: 600px;
  }

  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 550px;
  }
}
@media only screen and (min-width: 671px) and (max-width: 770px) {
  .tableResNo2 {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 671px) {
  .tableResNo2 {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 20px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 13px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 10px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .subTitle {
    padding-left: 20px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 120px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 60px;
    padding-right: 20px;
  }
  .applicationAgreementHead2 {
    padding-top: 20px;
    padding-left: 80px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 450px;
  }
  .QuarterText {
    min-width: 600px;
  }
  .agreementLogoDiv {
    min-width: 400px;
  }

  .searchListContentApp {
    font-size: 13px;
  }
}
@media only screen and (min-width: 430px) and (max-width: 580px) {
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 11px;
  }
  .headApplicationReview {
    font-size: 13px;
    line-height: 15px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 19px;
  }
  .informationListAppAlign {
    padding: 30px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .addApp {
    margin-top: -30px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }
  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    font-size: 12px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .inputContainer {
    padding: 20px;
  }
  .subTitle {
    padding-left: 20px;
    font-size: 15px !important;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 100px;
  }
  .applicationAgreement {
    padding-right: 20px;
    padding-left: 20px;
    padding-top: 30px;
  }

  .span7 {
    font-size: 11px;
  }
  .span8 {
    font-size: 12px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 300px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;
    min-width: 300px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 50px;
    padding-right: 45px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 40px;
    padding-left: 40px;
  }
  .halfBreak {
    min-width: 300px;
  }
  .Quarter {
    min-width: 300px;
  }
  .QuarterText {
    min-width: 500px;
  }
  .agreementTableBody {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementTableBody1 {
    margin-right: 15px;
    margin-left: 15px;
  }
  .agreementLogoDiv {
    min-width: 300px;
  }
  .applicationAgreementHead4 {
    padding-left: 40px;
    padding-right: 40px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 45px;
    font-size: 16px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
}

@media only screen and (min-width: 100px) and (max-width: 430px) {
  .preEmployeeHide2 {
    display: block;
  }
  .preEmployeeHide1 {
    display: none;
  }
  .titleStep {
    display: none;
  }
  .titleStepOpp {
    display: block;
  }
  .pageNumberAlign {
    text-align: center;
  }
  .tableResNo2 {
    display: block;
  }
  .tableResNo {
    display: block;
  }
  .tableLeftPad {
    padding-left: 8px;
  }
  .headApplicationReview {
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.7px;
  }
  .headApplicationReview1 {
    letter-spacing: 2px;
    font-size: 17px;
  }
  .informationListAppAlign {
    padding: 15px;
  }
  .searchListHeadApp {
    font-size: 12px;
  }
  .searchListHeadAppDark {
    font-size: 12px;
  }
  .applicationText {
    font-size: 13px;
  }
  .applicationText1 {
    font-size: 11px;
  }
  .appStatus {
    padding: 6px;
    font-size: 10px;
  }
  .addApp {
    margin-top: -30px;
  }
  .SideLine {
    display: none;
  }
  .SideLineHide {
    display: none;
  }

  .stepTitle {
    font-weight: 600;
    margin-top: 3px;
    white-space: normal;
    word-wrap: break-word;
    position: absolute;
    /* align-items: center; */
    font-size: 12px !important;
    align-self: center;
    line-height: 10px;
  }
  .stepFormNavTitle {
    padding-top: 25px;
  }
  .social-container {
    margin: 20px 0;
  }

  .social-container-step {
    border: 1px solid #dddddd;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 0px;
    height: 30px;
    width: 30px;
  }
  .social {
    font-size: 10px;
  }
  .stepSpan {
    font-family: "Montserrat", sans-serif;
    font-size: 8px;
    white-space: nowrap;
  }
  .inputContainer {
    padding: 10px;
  }
  .subTitle {
    padding-left: 10px;
    font-size: 15px !important;
  }

  .span7 {
    font-size: 10px;
  }
  .span8 {
    font-size: 11px;
  }
  .applicationAgreementHeadLeftBox {
    display: none;
  }
  .applicationAgreementHeadLeftBoxMobile {
    display: block;
    min-width: 200px;
  }
  .applicationAgreementHeadRight {
    padding: 0px;

    min-width: 200px;
  }
  .managerLandlord {
    min-width: 220px;
  }
  .applicationAgreementHead {
    padding-top: 30px;
    padding-left: 40px;
    padding-right: 35px;
  }
  .applicationAgreementHeadLeftBox {
    margin-top: 30px;
  }
  .applicationAgreement {
    padding-right: 5px;
    padding-left: 5px;
    padding-top: 30px;
  }
  .agreementLogo {
    width: 150px;
  }
  .checkedContainer {
    font-size: 11px;
  }
  .agreementCheck {
    min-width: 150px;
  }
  .agreementCheckService {
    min-width: 150px;
  }
  .apartmentAgreement1 {
    min-width: 200px;
  }
  .applicationAgreementHead3 {
    padding-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
  }
  .halfBreak {
    min-width: 200px;
  }
  .Quarter {
    min-width: 200px;
  }
  .QuarterText {
    min-width: 200px;
  }
  .agreementTableBody {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementTableBody1 {
    margin-right: 5px;
    margin-left: 5px;
  }
  .agreementLogoDiv {
    min-width: 200px;
  }
  .applicationAgreementHead4 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .ApplicationInput {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInputAgg {
    height: 45px;
    font-size: 16px;
  }
  .ApplicationInput1 {
    height: 35px;
    font-size: 14px;
    padding-bottom: 0px;
    margin-bottom: 0px;
  }
  .searchListContentApp {
    font-size: 11px;
  }
}

.contactStyle {
  background-image: url(/static/media/content5.04d38ad0.png);
  /* background-position-y: 00px;
     background-position-x: 100px; */
  background-size: cover;
  width: 66%;
  height: 735px;
  z-index: 100;
}
.contactStyleDark {
  background-image: url(/static/media/content6.97b9bc3c.png);
  /* background-position-y: 00px;
     background-position-x: 100px; */
  color: #fff !important;
  background-size: cover;
  width: 66%;
  height: 735px;
  z-index: 100;
}
.contactStyleDark input {
  color: rgb(235, 231, 231) !important;
}
.contactStyleDark textarea {
  color: rgb(235, 231, 231) !important;
}
.contactStyleDark input::-webkit-input-placeholder {
  color: rgb(212, 205, 205) !important;
}
.contactStyleDark input:-ms-input-placeholder {
  color: rgb(212, 205, 205) !important;
}
.contactStyleDark input::-ms-input-placeholder {
  color: rgb(212, 205, 205) !important;
}
.contactStyleDark input::placeholder {
  color: rgb(212, 205, 205) !important;
}
.contactStyleDark textarea::-webkit-input-placeholder {
  color: rgb(219, 212, 212) !important;
}
.contactStyleDark textarea:-ms-input-placeholder {
  color: rgb(219, 212, 212) !important;
}
.contactStyleDark textarea::-ms-input-placeholder {
  color: rgb(219, 212, 212) !important;
}
.contactStyleDark textarea::placeholder {
  color: rgb(219, 212, 212) !important;
}
.mapContact {
  position: absolute;
  height: 735px;
  width: 100%;
  /* margin-left: 300px; */
  z-index: 10;
}
.mapContactIn {
  border: 2px solid #6fa9e75d;
  width: 60%;
  height: 100%;
}
.contactTitle {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 1170px) and (max-width: 1310px) {
  .contactStyle {
    height: 655px;
    padding-left: 50px;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 655px;
    padding-left: 50px;
    padding-left: 40px !important;
  }
  .mapContact {
    height: 655px;
  }
}
@media only screen and (min-width: 1310px) and (max-width: 5000px) {
  .contactTitle {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .contactStyle {
    height: 715px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 715px;
    z-index: 100;
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1170px) {
  .contactTitle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .contactStyle {
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 820px) and (max-width: 990px) {
  .contactTitle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .contactStyle {
    width: 80%;
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    width: 80%;
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .mapContactIn {
    width: 50%;
  }
}
@media only screen and (min-width: 735px) and (max-width: 820px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactStyle {
    height: 555px;
    width: 90%;
    z-index: 100;
  }
  .contactStyleDark {
    height: 555px;
    width: 90%;
    z-index: 100;
  }
  .mapContactIn {
    width: 50%;
  }
}
@media only screen and (min-width: 580px) and (max-width: 820px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: url(/static/media/content4.190e852d.png),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 555px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: url(/static/media/content4.190e852d.png),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 555px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 580px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: linear-gradient(
      to top,
      rgba(245, 246, 252, 0.52),
      rgba(25, 70, 100, 0.73)
    );
    height: 455px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: linear-gradient(
      to top,
      rgba(245, 246, 252, 0.52),
      rgba(25, 70, 100, 0.73)
    );
    height: 455px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}
@media only screen and (min-width: 235px) and (max-width: 400px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: url(/static/media/content4.190e852d.png),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 505px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: url(/static/media/content4.190e852d.png),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 505px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}

/* Service */
.serviceBack {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.549),
      rgba(255, 255, 255, 0.85)
    ),
    url(/static/media/imageBack2.79099320.jpg);
  background-position: center;
  padding-bottom: 15%;
  padding-top: 5%;
}
.containerService {
  /* position: absolute; */
  margin-top: 5%;
  /* margin-bottom: 10%; */

  height: 300px;
  /* width: 600px; */

  justify-content: center;
  left: calc(50% - 300px);
  display: flex;
}
.containerService1 {
  display: none;
  justify-content: center;
}

.cardService {
  display: flex;
  height: 280px;
  width: 200px;
  /* min-width: 200px; */
  background-color: #4e80b6;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #000;
  /* margin-left: -50px; */
  transition: 0.4s ease-out;
  position: relative;
  left: 0px;
}
.cardService1 {
  margin-top: 5%;
  margin-bottom: 5%;
  height: 280px;
  /* width: 200px; */
  max-width: 400px;
  min-width: 200px;
  background-color: #2b315a;
  border-radius: 10px;
  box-shadow: -1rem 0 3rem #000;
  /* margin-left: -50px; */
  transition: 0.4s ease-out;

  left: 0px;
}
.cardService:not(:first-child) {
  margin-left: -30px;
}

.cardService:hover {
  transform: translateY(-20px);
  transition: 0.4s ease-out;
}

.cardService:hover ~ .card {
  position: relative;
  left: 50px;
  transition: 0.4s ease-out;
}

.titleService {
  color: white;
  font-weight: 500;
  position: absolute;
  left: 20px;
  top: 15px;
}

.barService {
  position: absolute;
  top: 100px;
  left: 20px;
  height: 5px;
  width: 150px;
}

.emptybarService {
  background-color: #1f2021;
  width: 100%;
  height: 100%;
}

.filledbarService {
  position: absolute;
  top: 0px;
  z-index: 3;
  width: 0px;
  height: 100%;
  background: rgb(0, 154, 217);
  background: linear-gradient(
    90deg,
    rgba(0, 154, 217, 1) 0%,
    rgba(217, 147, 0, 1) 65%,
    rgba(255, 186, 0, 1) 100%
  );
  transition: 0.6s ease-out;
}

.cardService:hover .filledbarService {
  width: 120px;
  transition: 0.4s ease-out;
}

.circleService {
  position: absolute;
  top: 150px;
  left: calc(50% - 60px);
}

.strokeService {
  stroke: white;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  transition: 0.6s ease-out;
}

svg {
  fill: #17141d;
  stroke-width: 2px;
}

.cardService:hover .stroke {
  stroke-dashoffset: 100;
  transition: 0.6s ease-out;
}

.contentStyle {
}
.content1 {
  /* background-color: #fff;
  z-index: 0; */
  margin-left: 20%;
  font-size: 15px;
  font-family: "Poppins", sans-serif;
}
:root {
  --red: hsl(0, 78%, 62%);
  --cyan: hsl(180, 62%, 55%);
  --orange: hsl(34, 97%, 64%);
  --blue: #4e80b6;
  --varyDarkBlue: hsl(234, 12%, 34%);
  --grayishBlue: hsl(229, 6%, 66%);
  --veryLightGray: hsl(0, 0%, 98%);
  --weight1: 200;
  --weight2: 400;
  --weight3: 600;
}

.boxCard p {
  color: hsl(229, 6%, 66%);
  color: var(--grayishBlue);
}

.boxCard {
  border-radius: 5px;
  box-shadow: 0px 30px 40px -20px hsl(229, 6%, 66%);
  box-shadow: 0px 30px 40px -20px var(--grayishBlue);
  padding: 30px;
  margin: 20px;
  width: 250px;
}
.boxCard:hover {
  cursor: pointer;
  box-shadow: rgba(174, 174, 179, 0.19) 0px 10px 20px,
    rgba(179, 182, 184, 0.23) 0px 6px 6px;
}
.cardImage {
  float: right;
}
@media (max-width: 350px) and (min-width: 0px) {
  .boxCard {
    height: auto;
    width: auto !important;
  }
  .row1-container {
    padding: 5%;
    padding-top: 14%;
  }
  .content1 {
    margin-left: 0%;
  }
}
@media (max-width: 350px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: auto !important;
  }
  .row1-container {
    padding: 5%;
    padding-top: 14%;
  }
}
@media (max-width: 450px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: 70%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .content1 {
    margin-left: 0%;
  }
  .boxCard {
    height: auto;
    width: 60%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 950px) and (min-width: 600px) {
  .boxCard {
    text-align: center;
    height: auto;
    width: 50%;
  }
  .content1 {
    margin-left: 0%;
  }
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.cyan {
  border-top: 3px solid hsl(180, 62%, 55%);
  border-top: 3px solid var(--cyan);
}
.red {
  border-top: 3px solid hsl(0, 78%, 62%);
  border-top: 3px solid var(--red);
}
.blue {
  border-top: 3px solid #4e80b6;
  border-top: 3px solid var(--blue);
}
.orange {
  border-top: 3px solid hsl(34, 97%, 64%);
  border-top: 3px solid var(--orange);
}

.cardSquareHead1 {
  color: hsl(234, 12%, 34%);
  color: var(--varyDarkBlue);
  font-weight: 600;
  font-weight: var(--weight3);
}
.cardSquareHead1Dark {
  color: #d5d5da;
  font-weight: 600;
  font-weight: var(--weight3);
}
.row2-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 950px) {
  .row1-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .row2-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .boxCard-down {
    position: relative;
    top: 150px;
  }
  .boxCard {
    width: 25%;
    /* width:320px; */
  }
}

.attribution {
  font-size: 11px;
  text-align: center;
}
.attribution a {
  color: hsl(228, 45%, 44%);
}

.content0 {
  background-image: url(/static/media/template01.894fc522.png);
  background-position: center;
  background-size: cover;
  z-index: 1;
  height: 595px;
}
.content0Dark {
  background-image: url(/static/media/template01.894fc522.png);
  background-position: center;
  background-size: cover;
  color: #fff;
  z-index: 1;
  height: 595px;
}
.alignContent0 {
  width: 50%;
}
.content0Align {
  padding-top: 15%;
  max-width: 800px;
  min-width: 480px;
}
.card1s-list {
  z-index: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  /* background-color: #fff; */
  flex-wrap: wrap;
}
.content1Align {
  /* background-color: #fff; */
}
.card1 {
  margin: 30px auto;
  width: 300px;
  height: 400px;
  border-radius: 40px;
  box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
    -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
  cursor: pointer;
  background-color: #fff;
  transition: 0.4s;
}

.card1 .card_image {
  width: inherit;
  height: inherit;
  border-radius: 40px;
}

.card1 .card_image img {
  width: inherit;
  height: inherit;
  height: 300px;
  border-radius: 40px;
  /* object-fit: cover; */
}

.card1 .card_title {
  text-align: center;
  border-radius: 0px 0px 40px 40px;
  padding: 10px;
  font-weight: bold;
  font-size: 30px;
  margin-top: -120px;
  height: 40px;
}

.card1:hover {
  transform: scale(0.9, 0.9);
  box-shadow: 5px 5px 30px 15px rgba(107, 106, 106, 0.25),
    -5px -5px 30px 15px rgba(124, 123, 123, 0.22);
}

.title-white {
  color: white;
}

.title-black {
  color: black;
}
.contentAbout {
  margin-top: 2%;
  margin-bottom: 50px;
  /* margin-left:5%;
     margin-right:5%; */
  /* width: 90%; */
}
.contentAboutProfile {
  background-image: url(/static/media/about-me.50542d95.jpg);

  background-size: cover;
  border: 10px solid #0b1c30;
  height: 355px;
  margin-top: 100px;
  z-index: 100;
}
.BFont {
  color: #9dc1e4ec;
  font-size: 70px;
}
.contentAbout2 {
  background: linear-gradient(to bottom, #0b1c30, #1e39533f);
  /* padding-bottom: 30px; */
  /* width:100%; */
  z-index: 100;
  white-space: nowrap;
}
.contentAbout0 {
  background-image: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0.981),
      rgba(255, 255, 255, 0.733),
      rgba(255, 255, 255, 0.981)
    ),
    url(/static/media/imageBack2.79099320.jpg);

  /* background-size: cover; */
  padding-bottom: 20%;
}
.contentAbout0Dark {
  padding-bottom: 20%;
  background-image: linear-gradient(
      to bottom,
      rgba(33, 37, 52, 0.946),
      rgba(33, 37, 52, 0.966),
      rgba(33, 37, 52, 0.953)
    ),
    url(/static/media/imageBack2.79099320.jpg);
}
.contentAbout1 {
  /* background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.938), rgba(18, 53, 102, 0.493)),url("../../../images/back3.jpg"); */
  /* position: absolute; */
  background-size: cover;

  height: 730px;

  z-index: 10;
}
.contentAbout3 {
  padding-left: 5%;
  padding-right: 5%;
}
.aboutTitle {
  color: #4e80b6;
}
.aboutContentContainer {
  background-image: linear-gradient(
    to bottom,
    rgba(155, 174, 214, 0.301),
    rgba(99, 150, 192, 0.404)
  );
  border-radius: 5px;
  padding: 5%;
  padding-top: 2%;
}
.aboutContent {
  font-size: 21px;
  line-height: 30px;
  color: #263d6b;
  letter-spacing: 0.7px;
  text-align: justify;
  text-shadow: 1px 1px #d5d5da;
  text-justify: inter-word;
}
@media all and (max-width: 500px) {
  .card-list {
    /* On small screens, we are no longer using row direction but column */
    flex-direction: column;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 300px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 17px;
    line-height: 21px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url(/static/media/back3.495d1b46.jpg);
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url(/static/media/imageBack2.79099320.jpg);
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0 {
    height: 300px;
  }
  .content0Dark {
    height: 300px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0 {
    height: 240px;
  }
  .content0Dark {
    height: 240px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0Align {
    padding-top: 7%;
    padding-left: 0%;
    max-width: 200px;
    min-width: 200px;
  }
  .card1 {
    height: 370px;
    width: 200px;
  }
  .card1 .card_title {
    margin-top: -70px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 410px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 17px;
    line-height: 21px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url(/static/media/back3.495d1b46.jpg);
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url(/static/media/imageBack2.79099320.jpg);
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 1%;
    padding-right: 1%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0 {
    height: 300px;
  }
  .content0Dark {
    height: 300px;
  }
  .alignContent0 {
    width: 40%;
  }
  .content0Align {
    padding-top: 8%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .card1 {
    width: 250px;
    height: 350px;
  }
  .card1 .card_title {
    margin-top: -60px;
  }
}
@media only screen and (min-device-width: 410px) and (max-device-width: 500px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 8%;
    padding-right: 8%;
    /* width: 90%; */
  }
  .aboutContent {
    font-size: 19px;
    line-height: 24px;
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url(/static/media/back3.495d1b46.jpg);
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url(/static/media/imageBack2.79099320.jpg);
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content0 {
    height: 450px;
    background-position: -70px;
  }
  .content0Dark {
    height: 450px;
    background-position: -70px;
  }
  .alignContent0 {
  }
  .content0Align {
    padding-top: 13%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
  .card1 {
    height: 350px;
  }
  .card1 .card_title {
    margin-top: -60px;
  }
}
@media only screen and (min-device-width: 500px) and (max-device-width: 550px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }
  .contentAbout {
    padding-left: 18%;
    padding-right: 18%;
    /* width: 90%; */
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url(/static/media/back3.495d1b46.jpg);
  }
  .contentAbout0Dark {
    background-image: linear-gradient(
        to bottom,
        rgba(33, 37, 52, 0.946),
        rgba(33, 37, 52, 0.953)
      ),
      url(/static/media/imageBack2.79099320.jpg);
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content0 {
    height: 450px;
    background-position: -70px;
  }
  .content0Dark {
    height: 450px;
    background-position: -70px;
  }
  .alignContent0 {
  }
  .content0Align {
    padding-top: 13%;
    padding-left: 0%;
    max-width: 800px;
    min-width: 200px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
  .card1 {
    height: 380px;
  }
  .card1 .card_title {
    margin-top: -90px;
  }
}
@media only screen and (min-device-width: 550px) and (max-device-width: 991px) {
  .containerService1 {
    display: block;
  }
  .containerService {
    display: none;
  }

  .contentAbout {
    padding-left: 18%;
    padding-right: 18%;
    /* width: 90%; */
  }
  .contentAbout0 {
    background-image: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.938),
        rgba(13, 38, 75, 0.747)
      ),
      url(/static/media/back3.495d1b46.jpg);
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .aboutTitle {
    color: #14375c;
  }
  .aboutContentContainer {
    background-image: linear-gradient(
      to bottom,
      rgba(220, 220, 220, 0.301),
      rgba(255, 255, 255, 0.404)
    );
  }
  .aboutContent {
    color: #263d6b;
    text-shadow: 1px 1px #d5d5da;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1270px) {
  .contentAbout {
    padding-left: 4%;
    padding-right: 4%;
    /* width: 90%; */
  }
  .cardService {
    width: 180px;
  }
  .contentAbout3 {
    padding-top: 5%;
    padding-left: 4%;
  }
  .content0Align {
    padding-top: 14%;
    max-width: 800px;
    min-width: 380px;
  }
  .content1 {
    margin-top: 20px;
    margin-left: 0%;
  }
  .content1Align {
    width: 100%;
  }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1400px) {
  .contentAbout {
    padding-left: 5%;
    padding-right: 5%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 5%;
  }
  .content0 {
    height: 855px;
  }
  .content0Dark {
    height: 855px;
  }
  .content0Align {
    padding-top: 15%;
    max-width: 600px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -170px;
  }
  .content1Align {
    width: 55%;
  }
}
@media only screen and (min-device-width: 1400px) and (max-device-width: 1800px) {
  .contentAbout {
    padding-left: 7%;
    padding-right: 7%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 7%;
  }
  .content0 {
    height: 915px;
  }
  .content0Dark {
    height: 915px;
  }
  .content0Align {
    padding-top: 15%;
    max-width: 800px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -120px;
  }
  .content1Align {
    width: 60%;
  }
}
@media only screen and (min-device-width: 1800px) and (max-device-width: 2100px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 955px;
  }
  .content0Dark {
    height: 955px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1200px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -120px;
  }

  .content1Align {
    width: 60%;
  }

  .card1 {
    margin: 30px auto;
    width: 400px;
    height: 450px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -160px;
    height: 40px;
  }
}
@media only screen and (min-device-width: 2100px) and (max-device-width: 2300px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 1105px;
  }
  .content0Dark {
    height: 1105px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1200px;
    min-width: 480px;
  }

  .content1Align {
    width: 60%;
  }
  .card1 {
    margin: 30px auto;
    width: 400px;
    height: 450px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -160px;
    height: 40px;
  }
  .content1 {
    margin-top: -100px;
  }
  .content1Align {
    width: 55%;
  }
}
@media only screen and (min-device-width: 2300px) and (max-device-width: 3000px) {
  .contentAbout {
    padding-left: 10%;
    padding-right: 10%;
    /* width: 90%; */
  }
  .contentAbout3 {
    padding-top: 7%;
    padding-left: 10%;
  }
  .content0 {
    height: 1105px;
  }
  .content0Dark {
    height: 1105px;
  }
  .content0Align {
    padding-top: 10%;
    max-width: 1500px;
    min-width: 480px;
  }
  .content1 {
    margin-top: -100px;
  }
  .content1Align {
    width: 60%;
  }
  .card1 {
    margin: 30px auto;
    width: 500px;
    height: 530px;
    border-radius: 40px;
    box-shadow: 5px 5px 20px 7px rgba(114, 114, 114, 0.25),
      -5px -5px 20px 7px rgba(99, 97, 97, 0.22);
    cursor: pointer;
    transition: 0.4s;
  }
  .card1 .card_title {
    text-align: center;
    border-radius: 0px 0px 40px 40px;
    padding: 20px;
    font-weight: bold;
    font-size: 30px;
    margin-top: -200px;
    height: 40px;
  }

  .content1Align {
    width: 55%;
  }
}

.show {
  display: block;
}
.hide {
  display: none;
}
.modalStyle {
  border-radius: 25px;
}
.modalContent {
  font-size: 19px;
  font-weight: 100;
  margin-top: -40px;
  margin-bottom: -20px;
}

.alertContain{
  position: fixed;
  right:15px;
  z-index: 1000000;
}


.NotificationWindow {
  z-index: 1000000;
  padding: 15px;
  min-width: 300px;
  max-width: 600px;
 
  border-radius: 10px;
  -webkit-animation: AlertRight 1s linear ;
          animation: AlertRight 1s linear ;
  opacity: 1;
}
.topMargin10{
  top: 10px !important;
}
.topMargin40{
  top: 100px !important;
}
.successAlert{
  background-color: rgba(77, 209, 108, 0.795) ;
  box-shadow: 0 4px 13px -8px rgb(55, 194, 55);
  color:rgb(13, 32, 13) ;
}
.successAlert .fa{
    color:rgb(23, 48, 23) ;
}
.successAlert .fa:hover{
  color:rgba(95, 158, 32, 0.637) ;
}
.successAlert .bar{
  background: rgb(17, 83, 17);
}
.warningAlert{
  background-color: rgba(252, 255, 85, 0.842);
  box-shadow: 0 4px 13px -8px rgba(252, 255, 85, 0.842);
  color:rgb(111, 112, 12) ;
}
.warningAlert .fa{
    color:rgba(109, 110, 27, 0.884);
}
.warningAlert .fa:hover{
  color:rgba(181, 184, 38, 0.842);
}
.warningAlert .bar{
  background: rgb(195, 207, 19);
}

.infoAlert{
  background-color: rgba(52, 133, 153, 0.842);
  box-shadow: 0 4px 13px -8px rgba(90, 202, 230, 0.842);
  color:rgba(255, 255, 255, 0.842);
}
.infoAlert .fa{
    color:rgba(255, 255, 255, 0.842);
}
.infoAlert .fa:hover{
  color:rgba(46, 95, 107, 0.842);
}
.infoAlert .bar{
  background: rgba(46, 95, 107, 0.842);
}

.dangerAlert{
  background-color: rgba(245, 76, 84, 0.822);
  box-shadow: 0 4px 13px -8px rgba(240, 71, 29, 0.842);
  color:rgba(255, 255, 255, 0.842);
}
.dangerAlert .fa{
    color:rgba(255, 255, 255, 0.842);
}
.dangerAlert .fa:hover{
  color:rgba(235, 127, 100, 0.842);
}
.dangerAlert .bar{
  background: rgba(243, 138, 112, 0.842);
}

.lightAlert{
  background-color: rgba(224, 220, 220, 0.885);
  box-shadow: 0 4px 13px -8px rgb(99, 98, 98);
}
@-webkit-keyframes AlertRight {
  0% {
    opacity: 0.2;
   
    /* display:block; */
  }

  50% { 
    opacity: 1;
    display: block;
     transform: translate(1px, 1px) rotate(0deg); }
  55% { transform: translate(-1px, -2px) rotate(-1deg); }
  60% { transform: translate(-3px, 0px) rotate(1deg); }
  65% { transform: translate(3px, 2px) rotate(0deg); }
  70% { transform: translate(1px, -1px) rotate(1deg); }
  75% { transform: translate(-1px, 2px) rotate(-1deg); }
  80% { transform: translate(-3px, 1px) rotate(0deg); }
  85% { transform: translate(3px, 1px) rotate(-1deg); }
  90% { transform: translate(-1px, -1px) rotate(1deg); }
  95% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); 
   }

}
@keyframes AlertRight {
  0% {
    opacity: 0.2;
   
    /* display:block; */
  }

  50% { 
    opacity: 1;
    display: block;
     transform: translate(1px, 1px) rotate(0deg); }
  55% { transform: translate(-1px, -2px) rotate(-1deg); }
  60% { transform: translate(-3px, 0px) rotate(1deg); }
  65% { transform: translate(3px, 2px) rotate(0deg); }
  70% { transform: translate(1px, -1px) rotate(1deg); }
  75% { transform: translate(-1px, 2px) rotate(-1deg); }
  80% { transform: translate(-3px, 1px) rotate(0deg); }
  85% { transform: translate(3px, 1px) rotate(-1deg); }
  90% { transform: translate(-1px, -1px) rotate(1deg); }
  95% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); 
   }

}


.notificationText {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 2px;
}
.notificationClose {
  margin-top: 10px;
  margin-right: 100px;
  z-index: 501;
  position: absolute;
}


.notificationText {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 2px;
  }

@media only screen and (min-width: 425px) and (max-width: 600px) {
    .notificationText {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 2px;
    }
}

@media only screen and (min-width: 200px) and (max-width: 425px) {
    .notificationText {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
    }
}
.footerContent {
  background-color: #003466;
  z-index: 1049;
  position: absolute;
}
.footer-content {
  max-width: 430px;
  min-width: 300px;
  z-index: 1049;
}

