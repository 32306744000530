.nav-link.active.chat {
  background-color: #4e80b6 !important;
  color: #fff !important;
}
.nav-link.chat {
  background-color: transparent !important;
  margin-top: 0px;
  color: #4e80b6 !important;
}
.chatIcon {
  font-size: 18px !important;
  padding-top: 4px;
  padding-left: 4px;
}
.nav-link:hover {
  background-color: #4e80b67e !important;
  color: #fff !important;
}
.chatContainer {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  /* margin-bottom: 20px; */
  min-width: 320px;
  max-width: 520px;
}

#topmenu span {
  float: left;
  width: 96px;
  height: 70px;
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/top-menu.png") -3px -118px
    no-repeat;
}

#topmenu span.friends {
  margin-bottom: -1px;
}
#topmenu span.chats {
  background-position: -95px 25px;
  cursor: pointer;
}
#topmenu span.chats:hover {
  background-position: -95px -46px;
  cursor: pointer;
}
#topmenu span.history {
  background-position: -190px 24px;
  cursor: pointer;
}
#topmenu span.history:hover {
  background-position: -190px -47px;
  cursor: pointer;
}
.friend {
  height: 70px;
  border-bottom: 1px solid #e7ebee;
  position: relative;
}
.friend:hover {
  background: #f1f4f6;
  cursor: pointer;
}
.friend img {
  width: 40px;
  border-radius: 50%;
  margin: 15px;
  float: left;
}
.floatingImg {
  width: 40px;
  border-radius: 50%;
  /* position: absolute; */
  top: 0;
  left: 12px;
  border: 3px solid #fff;
}
.friend p {
  padding: 15px 0 0 0 !important;
  margin: 0px !important;
  float: left !important;
  width: 220px !important;
}
.friend p strong {
  font-weight: 600 !important;
  font-size: 15px !important;
  color: #597a96 !important;
}
.friend p span {
  font-size: 13px !important;
  font-weight: 400 !important;
  color: #aab8c2 !important;
}
.friend .status {
  background: #26c281;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  position: absolute;
  top: 31px;
  right: 17px;
}
.friend .status.away {
  background: #ffce54;
}
.friend .status.inactive {
  background: #eaeef0;
}
.topmenu {
  /* height:520px; */
}
#friends {
  height: 463px;
  overflow-y: scroll;
}

#friends::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
#friends::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
#friends::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
#friends::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
#friends.animate {
  opacity: 1;
  margin-top: 0;
}
#search {
  background: #e3e9ed
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/search.png") -11px 0
    no-repeat;
  height: 60px;
  bottom: -70px;
  position: absolute;
  margin-top: 130px;
  width: 100%;
}
#searchfield {
  background: #e3e9ed;
  margin: 21px 0 0 55px;
  border: none;
  padding: 0;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400px;

  color: #8198ac;
}
#searchfield:focus {
  outline: 0;
}

#chatview {
  min-width: 320px;
  height: 523px;
  /* position:absolute; */
  top: 0;
  left: 0;
  /* display:none; */
  background: #fff;
  border-radius: 0px;
}

#profile {
  height: 130px;
  overflow: hidden;
  width: 100%;
  text-align: center;
  color: #fff;
}
.chatSetting {
  height: 523px;
  overflow-y: scroll !important;
}

.chatSetting::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
.chatSetting::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
.chatSetting::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
.chatSetting::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
.p1 #profile {
  background: #fff
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/timeline1.png") 0 0
    no-repeat;
  background-size: cover;
  height: 130px;
  border-radius: 0px;
}
#profile .avatar {
  width: 68px;
  border: 3px solid #fff;
  margin: 23px 0 0;
  border-radius: 50%;
}
#profile p {
  font-weight: 600;
  font-size: 15px;
  margin: 1px 0 -1px;
  /* opacity:0; */
  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#profile p.animate {
  margin-top: 97px;
  opacity: 1;
  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}
#profile span {
  font-weight: 400;
  font-size: 11px;
}
.chatProfile {
  width: 40px;
  border-radius: 50%;
  margin: 15px;
  border: 2px solid #fff;
}
#chat-messages {
  /* opacity:0; */
  /* margin-top: 30px; */
  min-width: 320px;
  height: 335px;
  overflow-y: scroll;
  overflow-x: hidden;
  /* padding-right: 20px; */
  -webkit-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -moz-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -ms-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  -o-transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
  transition: all 200ms cubic-bezier(0, 0.995, 0.99, 1);
}

#chat-messages::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}
#chat-messages::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgb(185, 179, 179);
  border-radius: 0px;
}
#chat-messages::-webkit-scrollbar-thumb {
  background: #4a6b8a;
  border-radius: 0px;
}
#chat-messages::-webkit-scrollbar-thumb:hover {
  background: #7c7a7a;
}
#chat-messages.animate {
  opacity: 1;
  margin-top: 0;
}
#chat-messages label {
  color: #aab8c2;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  margin: 15px 0;
  min-width: 320px;
  display: block;
}
#chat-messages div.message {
  padding: 0 0 5px 58px;
  clear: both;
  margin-bottom: 45px;
}
#chat-messages div.message.right {
  padding: 0 58px 0px 0;
  margin-right: 0px;
  margin-left: 19px;
}
#chat-messages .message img {
  float: left;
  margin-left: -38px;
  border-radius: 50%;
  width: 30px;
  margin-top: 4px;
}
#chat-messages div.message.right img {
  float: right;
  margin-left: 0;
  margin-right: -38px;
}
.message .bubble {
  background: #f0f4f7;
  font-size: 13px;
  font-weight: 500;
  padding: 12px 13px;
  border-radius: 5px 5px 5px 0px;
  color: #8495a3;
  /* position:relative; */
  float: left;
}
#chat-messages div.message.right .bubble {
  float: right;
  border-radius: 5px 5px 0px 5px;
}
.bubble .corner {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/bubble-corner.png")
    0 0 no-repeat;
  /* position:absolute; */

  width: 7px;
  height: 7px;
  left: 0px;
  bottom: 0;
}

div.message.right .corner {
  background: url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/245657/bubble-cornerR.png")
    0 0 no-repeat;
  left: auto;
  right: -5px;
}
.bubble {
  margin-bottom: 23px;
}
.message span {
  color: #aab8c2;
  font-size: 11px;
  font-weight: 100;
  /* position: absolute; */

  right: 0;
  bottom: -2px;
}
#sendmessage {
  height: 60px;
  border-top: 1px solid #e7ebee;
  /* position:absolute; */
  bottom: 0;
  right: 0px;

  background: #e3e9ed;
  padding-bottom: 50px;
}
#sendmessage i {
  font-size: 16px !important;
}
#sendmessage input {
  background: #fff;
  margin: 21px 0 0 21px;
  border: none;
  padding: 0;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400px;
  font-weight: 320px;
  width: 240px;

  background: #e3e9ed;
  color: #787b7c;
}
#sendmessage input:focus {
  outline: 0;
}
#sendmessage button {
  width: 30px;
  height: 30px;
  /* position:absolute; */
  /* right: 15px; */
  /* top: 23px; */
  border: none;
}
#sendmessage button:hover {
  cursor: pointer;
  background-position: 0 0;
}
#sendmessage button:focus {
  outline: 0;
}

#chatview,
#sendmessage {
  overflow: hidden;
  border-radius: 6px;
}
#chatview {
  border-radius: 0px !important;
}
#send {
  background: transparent !important;
  margin-right: 23px;
  margin-top: 15px;
  /* align-items: right;
  display:absolute; */
  /* right:0; */
  color: #4e80b6;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}

input[class="active"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #3cff00;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[class="away"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #ffa500;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
input[class="inactive"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: #5f5e5e;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid white;
}
@media only screen and (min-width: 1930px) and (max-width: 2250px) {
}
