.fillBoard {

  height: 40px;
  min-width: 170px;
  max-width: 300px;
  border: 1px solid #0000004b;
  border-radius: 5px;
  background-color: rgb(248, 248, 248);
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}
.fillBarDrag {
  border: 1px solid rgb(133, 126, 126);
  color:rgb(119, 113, 113);
}
.staticFaIcon{
  font-size: 15px;
}
.fillBar {
  height: 30px;
  cursor:pointer;
  box-shadow: rgba(39, 39, 58, 0.116) 0px 4px 16px,
    rgba(17, 17, 26, 0.24) 0px 8px 32px;
  color: rgb(255, 255, 255);
  background-color: rgba(117, 119, 151, 0.548);
  border-radius: 5px;
}
  .previewQuestionDark2 .fillBar{
    box-shadow: rgba(255, 255, 255, 0.1) 0px 4px 16px,
    rgba(255, 255, 255, 0.05) 0px 8px 32px;
}
.fillClose {
  border: none;
  color: #f8f7f7a8;
  background-color: transparent;
  cursor: pointer;
}

.fillClose:hover {
  border: none;
  color: #8f8c8c7e;
  background-color: transparent;
  cursor: pointer;
  border-radius: 5px;
}