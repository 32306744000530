.dropDownSelect {
  background-color: rgba(225, 231, 238, 0.897);
  border: 1px solid #fff;
  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
}

.dropDownSelectDark {
  background-color: rgba(241, 243, 245, 0.768);
  border: none;


  font-family: "Montserrat", sans-serif;
  /* padding: 12px 15px; */
  height: 45px;
  margin: 8px 0;
  width: 100%;
  font-size: 12px;
  color: #000;
}
.input-group-append{
  font-size: 12px ;
  margin: 8px 0 ;
}
.reviewInputNumber{
  margin-top: 0px;
 height:40px;
}
.textInput {
  border: 1px solid #fff !important;
  background-color: rgba(225, 231, 238, 0.768);
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px !important;
  margin: 8px 0 !important;
  width: 100%;
  /* min-width: 240px;
     max-width: 340px; */

  /* padding-right:50px; */
  font-size: 12px ;
}
.reviewMatch{
  height:40px !important;
  margin-bottom: 10px !important;
}
.reviewInput{
 height:40px !important;
}
.textInput1{
  border: 1px solid #fff !important;
  background-color: rgba(225, 231, 238, 0.768);

  font-family: "Montserrat", sans-serif;
  padding: 10px 15px !important;
  margin: 8px 0 !important;

  font-size: 12px ;
}

.textInputDark {
  border: 1px solid #fff !important;
  background-color: rgba(241, 243, 245, 0.768);
  font-family: "Montserrat", sans-serif;
  padding: 10px 15px;
  margin: 8px 0 ;
  width: 100%;
  color: #000;
  font-size: 12px;
}

.textInputDark1 {
  border: 1px solid #fff !important;
  background-color: rgba(241, 243, 245, 0.768);

  font-family: "Montserrat", sans-serif;
  padding: 10px 15px ;
  margin: 8px 0 !important;
  color: #000;
  font-size: 12px;
}
.createContentStyle {
  padding: 4%;
  margin: 0%;
  width: 100%;
  padding-top: 3%;
}
.createContentStyleDark {
  padding: 4%;
  margin: 0%;
  width: 100%;
  padding-top: 3%;
  color: #fff;
}
.createContentLabel {
  color: #000;
}
.createContentLabelDark {
  color: #b1c9e2;
}
@media only screen and (min-width: 400px) and (max-width: 500px) {
  .title {
    font-size: 29px;
    padding-top: 25px;
  }
}
@media only screen and (min-width: 220px) and (max-width: 400px) {
  .title {
    font-size: 25px;
    padding-top: 25px;
  }
  .dropDownSelect {
    margin: 8px 0;
    font-size: 12px;
  }
  .dropDownSelectDark {
    margin: 8px 0;
    font-size: 12px;
  }
  .textInput {
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 12px;
  }
  .textInputDark {
    padding: 12px 15px;
    margin: 8px 0;
    font-size: 12px;
  }
  .createContentStyle {
    padding: 4%;
    padding-top: 3%;
  }
  .createContentStyleDark {
    padding: 4%;
    padding-top: 3%;
  }
  .createContentLabel {
    font-size: 13px;
  }
  .createContentLabelDark {
    font-size: 13px;
  }
}
