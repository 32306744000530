@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");

.wrapper {
  /* margin: 1px auto; */
  max-width: 1100px;
}

.container-calendar {
  padding: 14px;
  padding-top: 0px;
  border-radius: 10px;
  min-width: 320px;
  max-width: 520px;

  /* display:none; */
  /* margin: 0 auto; */
  /* overflow: auto; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.calenderLight {
  background: #ffffff;
}
.calenderDark {
  background: #3e62798a;
}
.button-container-calendar button {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #bbbbbb2f;
  color: #fff;
  border: 1px solid #0aa2b5;
  border-radius: 4px;
  padding: 2px 10px;
}

.table-calendar {
  border-collapse: collapse;
  width: 100%;
}

.table-calendar td,
.table-calendar th {
  padding: 5px;

  /* border: 1px solid #e2e2e2; */
  text-align: center;
  vertical-align: top;
}
.dropdownMenuEvent {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.eventList {
  /* width:250px; */
  background-color: #fff;
}
.eventList:hover {
  /* width:250px; */
  color: #f3f3f3;
  background-color: #14293def;
}
.line-20 {
  line-height: 20px;
}
.date-picker {
  font-family: "Montserrat", sans-serif;
}
.date-picker1 {
  height: 40px;
}
.brake {
  display: block;
  margin-bottom: 7px;
  margin-top: 0px;
  line-height: 0px;
}
.brake1 {
  display: block;
  margin-bottom: 0px;
  margin-top: -11px;
  line-height: 0px;
}
.date-picker:hover {
  border-radius: 6px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: #fff !important;
  background-color: #4e80b65f;
}
.date-picker.selected {
  font-weight: bold;
  /* outline: 1px dashed #00BCD4; */
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 10px;
  color: #fff !important;
  background-color: #14293def;
}
.date-picker.selected:hover {
  border-radius: 10px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  color: #fff !important;
  background-color: #4e80b65f;
}
.calenderLight .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(212, 156, 72) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #fff0c1b2;
}
.calenderDark .date-picker.event {
  border-radius: 10px;
  cursor: pointer;
  color: rgb(107, 93, 73) !important;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: #eee56af8;
}
.date-picker.selected span {
  /* border-bottom: 2px solid currentColor; */
}
.removePaddings {
  padding: 0px;
  line-height: 1px;
  height: 1px;
  font-size: 8px;
}

.calenderLight .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #ffe283b2 !important;
  border-radius: 10px;
  background-color: #14293def;
  color: #ffe283b2;
}
.calenderDark .date-picker.selectedEvent {
  /* font-weight: bold; */
  cursor: pointer;
  outline: 3px solid #eee56af8 !important;
  border-radius: 10px;
  background-color: #fff;
  color: rgb(107, 93, 73);
}
.calenderLight .date-picker.selectedEvent:hover {
  outline: 1px solid #4e80b6 !important;
  cursor: pointer;
  border-radius: 10px;
  background-color: #ffe283b2;
  color: rgb(212, 156, 72) !important;
}
.calenderDark .date-picker.selectedEvent:hover {
  cursor: pointer;
  /* outline: 3px solid #71b1f5 !important; */
  border-radius: 10px;
  background-color: #14293def;
  color: rgb(107, 93, 73) !important;
}
/* sunday */
.date-picker:nth-child(1) {
  color: rgb(204, 84, 84);
}

/* friday */
.date-picker:nth-child(6) {
  color: rgb(80, 182, 80);
}

.monthAndYear {
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  text-align: center;
  color: #fff;
  margin-top: 0;
}
.weeks {
  color: rgb(182, 182, 186);
  font-weight: 400;
}
.badge-throo {
  background-color: #3be8c8;
  color: #fff;
}
.badge-throo2 {
  background-color: #53535373;
  color: #fff;
}
.calenderEventMessage {
  font-family: "Montserrat", sans-serif;
  line-height: 5px !important;
  color: #000 !important;
  letter-spacing: 0.5px;
  padding-top: 1px !important;
  padding-bottom: 4px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;

  margin: 0px 0 0px !important;
}
.calenderEventMessage:hover {
  color: #fff !important;
}
.button-container-calendar {
  background-color: #4e80b6;
  position: relative;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  margin-bottom: 0.5em;
  overflow: hidden;
  clear: both;
}

.previous {
  float: left;
  font-size: 16px !important;
}

.next {
  float: right;
  font-size: 16px !important;
}

.footer-container-calendar {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar-dark {
  font-family: "Montserrat", sans-serif;
  margin-top: 1em;
  border-top: 1px solid #dadada;
  padding: 10px 0;
}
.footer-container-calendar select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #ffffff;
  color: #585858;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select {
  cursor: pointer;
  display: inline-block;
  zoom: 1;
  background: #5a748563;
  color: #f3f3f3;
  border: 1px solid #bfc5c5;
  border-radius: 3px;
  padding: 5px 1em;
}

.footer-container-calendar-dark select option {
  color: rgb(70, 67, 67);
}
