@import url("https://fonts.googleapis.com/css?family=Montserrat:400,800");
.App {
  text-align: center;
}
.bgDarkThroout {
  background-color: #212534;
}
.bgPriThroout {
  background-color: #4e80b6;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.absolute {
  position: absolute;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bgPri {
  background-color: #4e80b6;
}

.bgPriRad {
  background: -webkit-linear-gradient(to right, #5a8abd, #0d4172);
  background: linear-gradient(to right, #5a8abd, #0d4172);
}
.bgPriRad2 {
  background: -webkit-linear-gradient(to right, #5a8abd, #5d8dc0);
  background: linear-gradient(to right, #4e80b6, #5386bb);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: 1px solid rgba(255, 255, 255, 0.603);
}
.textPri {
  color: #4e80b6;
}
.textPri2 {
  color: #f86e56;
}
.textPriHover {
  color: #4e80b6;
}
.textPriHover:hover {
  color: rgb(29, 51, 117);
}
.borderPri {
  border: 1px solid #4e80b6;
}
.bgSec {
  background-color: rgba(224, 220, 220, 0.603);
}
.textSec {
  color: rgba(224, 220, 220, 0.603);
}
.textDark {
  color: rgba(102, 95, 95, 0.734);
}
.borderSec {
  border: 1px solid rgba(224, 220, 220, 0.603);
}
.pointer {
  cursor: pointer;
}
.disable {
  cursor: not-allowed;
}
.axText {
  font-family: "Montserrat", sans-serif;
}
.loginModal {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: "Montserrat", sans-serif;
  height: 100vh;
  /* margin: 0px 50px; */
}
.logo {
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-weight: 1950;
}
h1 {
  font-weight: bold;
  margin: 0;
}
h1.one {
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-weight: 1950;
  padding-left: 15px;
}
h2 {
  text-align: center;
}
strong {
  font-weight: 1000 !important;
}
p {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
p.nav {
  font-size: 14px;
  font-weight: 100;
  line-height: 20px;
  letter-spacing: 0.5px;
  margin: 20px 0 30px;
}
span {
  font-size: 15px;
}
.span1 {
  font-size: 20px;
  line-height: 30px;
}
.span2 {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  white-space: nowrap;
}

.spanvalidate {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  align-self: flex-end;
  padding-right: 10px;
  /* position:fixed; */
  padding-top: 0px;
}
a {
  font-size: 14px;
  text-decoration: none;
  margin: 15px 0;
}
/* 
button {
  border-radius: 20px;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
}

button:active {
  transform: scale(0.95);
}

button:focus {
  outline: none;
} */
.buttonDisabled {
  border: solid 1px #a79e9d !important;
  background-color: transparent !important;
  color: #a79e9d !important;
  cursor: not-allowed !important;
}

.buttonPri {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: #4e80b6;
  color: #fff !important;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonPri:hover {
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6 !important;
}
.buttonPri:active {
  transform: scale(0.95);
}
.buttonPri:focus {
  outline: none;
}

.buttonLight {
  border-radius: 20px;
  border: solid 1px #fcfcfc;
  background-color: #f5f5f5;
  color: #4e80b6;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonLight:hover {
  border: solid 1px #fcfcfc;
  background-color: transparent;
  color: #fcfcfc;
}
.buttonLight:active {
  transform: scale(0.95);
}
.buttonLight:focus {
  outline: none;
}

.buttonLightTran {
  border-radius: 20px;
  border: solid 1px #fcfcfc;
  background-color: transparent;
  color: #fcfcfc;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.buttonLightTran:hover {
  border: solid 1px #fcfcfc;
  background-color: #f5f5f5;
  color: #4e80b6;
}
.buttonLightTran:active {
  transform: scale(0.95);
}
.buttonLightTran:focus {
  outline: none;
}
.buttonDark {
  border-radius: 20px;

  border: solid 0px #fff;
  background: linear-gradient(to right, #928e8e52, rgba(170, 163, 163, 0.707));
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonDark:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px #fff;
  /* color:#616161; */
}
.buttonBlack {
  border-radius: 5px;

  border: solid 0px #fff;
  background: linear-gradient(to right, #1d1d1d52, rgba(131, 131, 131, 0.707));
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}

.buttonBlack:hover {
  /* border: solid 1px #fff; */
  /* background: linear-gradient(to right, #979696, rgb(228, 227, 227)); */
  box-shadow: 0 0px 40px rgba(255, 255, 255, 0.349);
  /* color:#616161; */
}
.rad15 {
  border-radius: 15px;
}
.buttonDark1 {
  border: solid 1px #5f5a5a52;
  color: #5f5a5a52;
  /* background: linear-gradient(to right, #e6e4e4, rgb(235, 231, 231)); */
  background-color: transparent;
}
button.ghost {
  background-color: transparent;
}
button.pri {
  border-radius: 20px;
  border: solid 1px #4e80b6;
  background-color: transparent;
  color: #4e80b6 !important;
  font-size: 12px;
  font-weight: bold;
  padding: 10px 30px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
button.pri:hover {
  background-color: #4e80b6 !important;
  border-color: transparent;
  color: #fff !important;
}
button.pri:active {
  transform: scale(0.95);
}
.formlogin {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 50px;
  height: 100%;
  text-align: center;
}

input.tag {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 12px 15px;
  margin: 8px 0;
  width: 100%;
}
input.answer {
  border: none;
  font-family: "Montserrat", sans-serif;
  padding: 8px 15px;
  margin: 8px 0;
  border-radius:10px;
  width: 100%;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
input.alert {
}
input {
  font-family: "Montserrat", sans-serif;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

.box {
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12);
}
.effect7 {
  position: relative;
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3),
    0 0 40px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect7:before,
.effect7:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
  top: 0;
  bottom: 0;
  left: 10px;
  right: 10px;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.effect7:after {
  right: 10px;
  left: auto;
  -webkit-transform: skew(8deg) rotate(3deg);
  -moz-transform: skew(8deg) rotate(3deg);
  -ms-transform: skew(8deg) rotate(3deg);
  -o-transform: skew(8deg) rotate(3deg);
  transform: skew(8deg) rotate(3deg);
}
.effect8 {
  position: relative;
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3),
    0 0 20px rgba(0, 0, 0, 0.1) inset;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 0, 0, 0.1) inset;
}
.effect8:before,
.effect8:after {
  content: "";
  position: absolute;
  z-index: -1;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  top: 10px;
  bottom: 10px;
  left: 0;
  right: 0;
  -moz-border-radius: 100px / 10px;
  border-radius: 100px / 10px;
}
.effect8:after {
  right: 10px;
  left: auto;
  /* -webkit-transform:skew(8deg) rotate(3deg);
	   -moz-transform:skew(8deg) rotate(3deg);
	    -ms-transform:skew(8deg) rotate(3deg);
		-o-transform:skew(8deg) rotate(3deg);
		   transform:skew(8deg) rotate(3deg); */
}

.shadow1 {
  box-shadow: 0px 0 25px rgba(0, 0, 0, 0.4);
}
.shadow2 {
  box-shadow: 7px 7px 25px rgba(236, 233, 233, 0.871);
}
.box2 {
  box-shadow: 0 2.8px 2.2px rgba(206, 203, 203, 0.034),
    0 6.7px 5.3px rgba(212, 211, 211, 0.048),
    0 12.5px 10px rgba(228, 227, 227, 0.06),
    0 22.3px 17.9px rgba(194, 191, 191, 0.072),
    0 41.8px 33.4px rgba(206, 205, 205, 0.086),
    0 100px 80px rgba(230, 228, 228, 0.12);
}
.se-toolbar {
  background-color: rgba(230, 233, 238, 0.912) !important;
  /* border: 1px solid rgb(228, 228, 228) !important; */
}
.se-menu-list {
  background-color: rgba(214, 217, 223, 0.994) !important;
  /* border: 1px solid rgb(201, 199, 199) !important; */
}
.se-btn:hover {
  background-color: rgba(242, 243, 243, 0.768) !important;
}
.se-btn.active {
  background-color: #003466 !important;
  color: rgb(144, 199, 250) !important;
}

@font-face {
  font-family: "Bamini";
  font-style: normal;
  font-weight: 5;
  src: local("Bamini"), url("languages/Bamini.woff") format("woff");
}
@font-face {
  font-family: "SU Sewandi";
  font-style: normal;
  font-weight: 5;
  src: local("SU Sewandi"), url("languages/SU Sewandi.ttf") format("truetype");
}
@font-face {
  font-family: "DL-Sarala";
  font-style: normal;
  font-weight: 5;
  src: local("DL-Sarala"), url("languages/DL-Sarala.TTF") format("truetype");
}
@font-face {
  font-family: "Kusum";
  font-style: normal;
  font-weight: 5;
  src: local("Kusum"), url("languages/Kusum.ttf") format("truetype");
}
@media only screen and (min-width: 300px) and (max-width: 850px) {
  .buttonPri {
    font-size: 10px;

    padding: 8px 30px;
  }
}
