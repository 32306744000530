.questionPart {

    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
   
}
.badge-transparent{
  background-color: rgba(36, 35, 35, 0.384);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
/* The container */
.containerRadio {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark {
  position: absolute;
  top: 15px;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel {
  padding-left: 40px;
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark {
  padding-left: 40px;
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio:hover input ~ .radioMark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.containerRadio input:checked ~ .radioMark {
  background-color: rgb(71, 233, 106);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio input:checked ~ .radioMark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio .radioMark:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

/* The container */
.containerRadio2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.containerRadio2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark2 {
  position: absolute;
  top: 7px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid #fff;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel2 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark2 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio2:hover input ~ .radioMark2 {
  background-color: rgba(243, 243, 243, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio2 input:checked ~ .radioMark2 {
  background-color: rgba(0, 0, 0, 0.323);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark2:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio2 input:checked ~ .radioMark2:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio2 .radioMark2:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
@media only screen and (min-width: 300px) and (max-width: 750px) {
  .radioMarkLabel {
    display: none;
  }
  .radioMarkLabelDark {
    display: none;
  }
  .checkMarkLabel {
    display: none;
  }
  .checkMarkLabelDark {
    display: none;
  }
}

select {
  font-family: "FontAwesome", "sans-serif";
}
textarea.answer {
  padding: 10px;
  width: 100%;
  line-height: 1.5;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: 1px 1px 1px #999;
}
.answerPre {
  /* padding: 10px; */
  margin-top: 5px;
  /* line-height: 0.7; */
  border-radius: 5px;
  border: 1px solid #ccc;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

/* The container */
.containerCheck {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheck input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMark {
  position: absolute;
  top: 15px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(110, 108, 108, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.checkMarkLabel {
  padding-left: 40px;
  color: rgb(28, 65, 36);
}

.checkMarkLabelDark {
  padding-left: 40px;
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerCheck:hover input ~ .checkMark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheck input:checked ~ .checkMark {
  background-color: rgb(71, 233, 106);
}

/* Create the checkMark/indicator (hidden when not checked) */
.checkMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMark when checked */
.containerCheck input:checked ~ .checkMark:after {
  display: block;
}

/* Style the checkMark/indicator */
.containerCheck .checkMark:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* The container */
.containerCheckPre {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckPre input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMarkPre {
  position: absolute;
  top: 12px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(197, 197, 197, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* On mouse-over, add a grey background color */
.containerCheckPre:hover input ~ .checkMarkPre {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckPre input:checked ~ .checkMarkPre {
  background-color: rgb(71, 233, 106);
}

/* Create the checkMarkPre/indicator (hidden when not checked) */
.checkMarkPre:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMarkPre when checked */
.containerCheckPre input:checked ~ .checkMarkPre:after {
  display: block;
}

/* Style the checkMarkPre/indicator */
.containerCheckPre .checkMarkPre:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}



/* The container */
.containerCheckPre2 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.containerCheckPre2 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom checkbox */
.checkMarkPre {
  position: absolute;
  top: 12px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(242, 228, 228);
  border: 1px solid rgba(197, 197, 197, 0.5);
  /* border-radius: 50%; */
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

/* On mouse-over, add a grey background color */
.containerCheckPre2:hover input ~ .checkMarkPre {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.containerCheckPre2 input:checked ~ .checkMarkPre {
  background-color:rgb(187, 54, 2);;
}

/* Create the checkMarkPre/indicator (hidden when not checked) */
.checkMarkPre:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkMarkPre when checked */
.containerCheckPre2 input:checked ~ .checkMarkPre:after {
  display: block;
}

/* Style the checkMarkPre/indicator */
.containerCheckPre2 .checkMarkPre:after {
  left: 7px;
  top: 3px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.checkMarkPreLabel {
  color: rgb(28, 65, 36);
}

.checkMarkLabelPreDark {
  color: rgba(238, 236, 236, 0.685);
}
.previewQuestion {
  background-color: rgba(255, 255, 255, 0.534);
  min-height: 100px;
  padding-bottom: 30px;
}
.previewQuestion2 {
  background-color: rgba(202, 205, 206, 0.205);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(100, 100, 111, 0.034) 0px 7px 29px 0px;
}
.previewQuestion2Correct {
  background-color: rgba(46, 216, 111, 0.466);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestion2Wrong {
  background-color: rgba(255, 82, 51, 0.562);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestionDark2Wrong {
  background-color: rgba(240, 101, 76, 0.562);
  min-height: 100px;
  border-radius: 10px;
  padding-bottom: 30px;
  border: 1px solid rgba(21, 28, 63, 0.075);
  box-shadow: rgba(43, 190, 104, 0.034) 0px 7px 29px 0px;
}
.previewQuestionDark2 {
  border-radius: 10px;
  background-color: rgba(9, 9, 31, 0.377);
  min-height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
}
.previewQuestionDark2Correct {
  border-radius: 10px;
  background-color: rgba(46, 216, 111, 0.466);
  min-height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  color: rgb(255, 255, 255);
  padding-bottom: 30px;
}
.previewQuestionDark {
  background-color: rgba(65, 63, 63, 0.548);
  min-height: 100px;
  padding-bottom: 30px;
}
.answerAlignment {
  min-width: 300px;
  /* max-width:500px; */
}
.darkHR {
  height: 1px;
  color: #ccc;
  background-color: #ccc;
  border: none;
}
.lightHR {
}

/* The container */
.containerRadio3 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -7px;
}

/* Hide the browser's default radio button */
.containerRadio3 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark3 {
  position: absolute;
  top: 9px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid rgba(156, 155, 155, 0.466);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel3 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark3 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio3:hover input ~ .radioMark3 {
  background-color: rgba(218, 212, 212, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio3 input:checked ~ .radioMark3 {
  background-color: rgb(71, 233, 106);
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio3 input:checked ~ .radioMark3:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio3 .radioMark3:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}


.matchingMobile{
  display: none;
}
.matchingWeb{
  display: block;
}

/* The container */
.containerRadio4 {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -7px;
}

/* Hide the browser's default radio button */
.containerRadio4 input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radioMark3 {
  position: absolute;
  top: 9px;

  left: 0;
  height: 20px;
  width: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgba(110, 108, 108, 0.5);
  border-radius: 50%;
  border: 1px solid rgba(156, 155, 155, 0.466);
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
.radioMarkLabel3 {
  color: rgb(28, 65, 36);
}
.radioMarkLabelDark3 {
  color: rgba(238, 236, 236, 0.685);
}
/* On mouse-over, add a grey background color */
.containerRadio4:hover input ~ .radioMark3 {
  background-color: rgba(218, 212, 212, 0.562);
}

/* When the radio button is checked, add a blue background */
.containerRadio4 input:checked ~ .radioMark3 {
  background-color: rgb(187, 54, 2);
}


/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark3:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.containerRadio4 input:checked ~ .radioMark3:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.containerRadio4 .radioMark3:after {
  top: 5px;
  left: 5px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
@media only screen and (min-width: 550px) and (max-width: 700px) {
.matchingConnecter{
  display: none;
}
.matchingConnecterHR{
  display: none;
}
}
@media only screen and (min-width: 380px) and (max-width: 550px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }
}

@media only screen and (min-width: 300px) and (max-width: 380px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }

.matchingInput{
  width:230px;
}
}

@media only screen and (min-width: 0px) and (max-width: 360px) {
  .matchingConnecterHR{
    display: none;
  }
  .matchingMobile{
    display: block;
  }
  .matchingWeb{
    display: none;
  }

.matchingInput{
  width:190px;
}
}