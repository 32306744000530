.contactStyle {
  background-image: url("../../../images/content5.png");
  /* background-position-y: 00px;
     background-position-x: 100px; */
  background-size: cover;
  width: 66%;
  height: 735px;
  z-index: 100;
}
.contactStyleDark {
  background-image: url("../../../images/content6.png");
  /* background-position-y: 00px;
     background-position-x: 100px; */
  color: #fff !important;
  background-size: cover;
  width: 66%;
  height: 735px;
  z-index: 100;
}
.contactStyleDark input {
  color: rgb(235, 231, 231) !important;
}
.contactStyleDark textarea {
  color: rgb(235, 231, 231) !important;
}
.contactStyleDark input::placeholder {
  color: rgb(212, 205, 205) !important;
}
.contactStyleDark textarea::placeholder {
  color: rgb(219, 212, 212) !important;
}
.mapContact {
  position: absolute;
  height: 735px;
  width: 100%;
  /* margin-left: 300px; */
  z-index: 10;
}
.mapContactIn {
  border: 2px solid #6fa9e75d;
  width: 60%;
  height: 100%;
}
.contactTitle {
  padding-top: 30px;
  padding-bottom: 30px;
}
@media only screen and (min-width: 1170px) and (max-width: 1310px) {
  .contactStyle {
    height: 655px;
    padding-left: 50px;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 655px;
    padding-left: 50px;
    padding-left: 40px !important;
  }
  .mapContact {
    height: 655px;
  }
}
@media only screen and (min-width: 1310px) and (max-width: 5000px) {
  .contactTitle {
    padding-top: 50px;
    padding-bottom: 30px;
  }
  .contactStyle {
    height: 715px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 715px;
    z-index: 100;
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 990px) and (max-width: 1170px) {
  .contactTitle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .contactStyle {
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
}
@media only screen and (min-width: 820px) and (max-width: 990px) {
  .contactTitle {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .contactStyle {
    width: 80%;
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .contactStyleDark {
    width: 80%;
    height: 555px;
    z-index: 100;
    padding-left: 40px !important;
  }
  .mapContactIn {
    width: 50%;
  }
}
@media only screen and (min-width: 735px) and (max-width: 820px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactStyle {
    height: 555px;
    width: 90%;
    z-index: 100;
  }
  .contactStyleDark {
    height: 555px;
    width: 90%;
    z-index: 100;
  }
  .mapContactIn {
    width: 50%;
  }
}
@media only screen and (min-width: 580px) and (max-width: 820px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: url("../../../images/content4.png"),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 555px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: url("../../../images/content4.png"),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 555px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}
@media only screen and (min-width: 400px) and (max-width: 580px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: linear-gradient(
      to top,
      rgba(245, 246, 252, 0.52),
      rgba(25, 70, 100, 0.73)
    );
    height: 455px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: linear-gradient(
      to top,
      rgba(245, 246, 252, 0.52),
      rgba(25, 70, 100, 0.73)
    );
    height: 455px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}
@media only screen and (min-width: 235px) and (max-width: 400px) {
  .contactTitle {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .contactTitle {
    color: #fff;
  }
  .contactInput {
    background-color: rgba(255, 255, 255, 0.603);
    font-size: 12px;
  }
  .contactStyle {
    background-image: url("../../../images/content4.png"),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 505px;
    width: 100%;
    z-index: 100;
  }
  .contactStyleDark {
    background-image: url("../../../images/content4.png"),
      linear-gradient(
        to top,
        rgba(245, 246, 252, 0.52),
        rgba(25, 70, 100, 0.73)
      );
    height: 505px;
    width: 100%;
    z-index: 100;
  }
  .mapContactIn {
    width: 100%;
  }
}
